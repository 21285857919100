import { Form, Input, Button } from "antd";
import { useLogin } from "../../../hooks/useLogin";

// import styleGlobal from "../Global.module.css";
import style from "./Login.module.css";
import React, { useState } from "react";
import logo from "../../images/connect.svg";

const Login = (props: any) => {
  const [benutzername, setBenutzername] = useState("");
  const [passwort, setPasswort] = useState("");

  const { loginInfo } = useLogin(
    {
      benutzername: benutzername,
      passwort: passwort,
    },
    { enabled: benutzername !== "" && passwort !== "" }
  );

  if (loginInfo.benutzername) {
    // eslint-disable-next-line
    const myTimer = setTimeout(() => {
      props.setLoggedIn(true);
    }, 100);
  }

  const OnFinish = (values: any) => {
    setBenutzername(values.benutzername.trim());
    setPasswort(values.passwort.trim());
  };

  return (
    <div className={style.loginContainer}>
      <div className={style.loginLogo}>
        <img src={logo} className="App-logo" alt="e-cut Connect" />
      </div>
      <div className={style.connectLogo}>
        <div className={style.loginFormContainer}>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 6 }} initialValues={{ remember: false }} onFinish={OnFinish}>
            <Form.Item
              label="Benutzername"
              name="benutzername"
              rules={[{ required: true, message: "Bitte geben Sie Ihren e-cut Connect Benutzernamen ein." }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Passwort"
              name="passwort"
              rules={[{ required: true, message: "Bitte geben Sie Ihr e-cut Connect Passwort ein." }]}
            >
              <Input.Password />
            </Form.Item>

            {/*
        <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
          <Checkbox>Angemeldet bleiben</Checkbox>
        </Form.Item>
        */}
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Login
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
