import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { Layout, Result } from "antd";

import NavTop from "../pages/NavTop/NavTop";
import NavLeft from "../pages/NavLeft/NavLeft";
import Login from "../pages/Login/Login";
import Statistik from "../pages/Statistiken/Statistik";

import Home from "../pages/Home/Home";
import Datev from "../pages/Datev/Datev";
import Arbeitszeiten from "../pages/Arbeitszeiten/Arbeitszeiten";

import style from "./App.module.css";
import { QueryClient, QueryClientProvider } from "react-query";
import UserManager from "../pages/UserManager/UserManager";

import logo from "../images/connect.svg";
import { Content } from "antd/es/layout/layout";
import SollArbeitszeiten from "../pages/SollArbeitszeiten/SollArbeitszeiten";

import Planer from "../pages/Planer/Planer";
import Context from "@ant-design/icons/lib/components/Context";
import { atom } from "jotai";
import { useAtom } from "jotai";
import { GENERICTYPE_PARAMETER_LOCALDB, GENERICTYPE_PARAMETER_MSM } from "../../types/genericTypes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false },
  },
});

/**
 * Call
 * connect.exe ipAdresseServer isMSM licenseCodeServer isLocalDB activeModules activeMAID optActiveModuleSubID optAdditionalParameters
 *
 * ipAdresseServer = "127.0.0.1";
 * isMSM = "0"; // 0..e-cut; 1..MSM
 * licenseCodeServer = "1234-1234-1234-1234-1234";
 * isLocalDB = "0"; // 0..local; 1..Server
 * activeModules = "connect";
 * activeMAID = "0";
 * connect =  normales Connect
 *  dienstplan
 *  arbeitszeitnachweis
 *  statistik
 *  forecast
 *  einstellungen
 *
 * optActiveModuleSubID = "1"; // ID für Object innerhalb des gewählten Modules Bsp. StatistikID bei Statistiken
 * optAdditionalParameters = "{}"; // JSON Object
 */

export let cmdParameter = {
  ipAdresseServer: "127.0.0.1",
  isMSM: GENERICTYPE_PARAMETER_MSM.GENERICTYPE_PARAMETER_MSM_CONNECT,
  licenseCodeServer: "1234-1234-1234-1234-1234",
  isLocalDB: GENERICTYPE_PARAMETER_LOCALDB.GENERICTYPE_PARAMETER_LOCALDB_LOCAL,
  activeModules: "connect",
  activeMAID: "0",
  optActiveModuleSubID: "1",
  optAdditionalParameters: "{}",
};

/**
 * ATOM Konfiguration
 */
const loggedInLS: boolean = localStorage.loginInfo && JSON.parse(localStorage.loginInfo).benutzername ? true : false;
const atomOffline = atom(false);
const atomLoggedIn = atom(loggedInLS);
const atomIsLocalEcut = atom(true);

const App = () => {
  const [isOffline, setIsOffline] = useAtom(atomOffline);
  const [isLocalEcut, setIsLocalEcut] = useAtom(atomIsLocalEcut);
  document.documentElement.lang = "de";

  const loggedInLS: boolean = localStorage.loginInfo && JSON.parse(localStorage.loginInfo).benutzername ? true : false;

  // da noch nicht eingebaut
  // eslint-disable-next-line
  const [isServerDown, setIsServerDown] = useState(false);

  const [loggedIn, setLoggedIn] = useAtom(atomLoggedIn);

  const [isToggled, setToggled] = useState(false);

  const [isNavLeftVisible, setIsNavLeftVisible] = useState(true);

  // Übergebene Parameter setzen (ts-ignore notwendig, da electronAPI nicht vorhanden ist, sondern über preload.ts dynamisch eingeladen wird)
  // @ts-ignore
  if (window.electronAPI) {
    // @ts-ignore
    window.electronAPI.handleNotifyNewArgV((event, value) => {
      if (value[1]) {
        cmdParameter.ipAdresseServer = value[1];
      }
      if (value[2] && value[2] == "1") {
        cmdParameter.isMSM = GENERICTYPE_PARAMETER_MSM.GENERICTYPE_PARAMETER_MSM_MSM;
      }
      if (value[3]) {
        cmdParameter.licenseCodeServer = value[3];
      }
      if (value[4] && value[4] == GENERICTYPE_PARAMETER_LOCALDB.GENERICTYPE_PARAMETER_LOCALDB_SERVER) {
        cmdParameter.isLocalDB = GENERICTYPE_PARAMETER_LOCALDB.GENERICTYPE_PARAMETER_LOCALDB_SERVER;
      }
      if (value[5]) {
        cmdParameter.activeModules = value[5];
      }
      if (value[6]) {
        cmdParameter.activeMAID = value[6];
      }
      if (value[7]) {
        cmdParameter.optActiveModuleSubID = value[7];
      }
      if (value[8]) {
        cmdParameter.optAdditionalParameters = value[8];
      }
    });
  }

  const toggleTrueFalse = () => setToggled(!isToggled);
  const onClose = () => {
    setToggled(false);
  };

  const onOffline = () => {
    setIsOffline(true);
  };

  const onOnline = () => {
    setIsOffline(false);
  };

  const handleContextMenu = (e: Event) => {
    e.preventDefault();
  };

  useEffect(() => {
    window.addEventListener("offline", onOffline);
    window.addEventListener("online", onOnline);
    window.addEventListener("contextmenu", handleContextMenu);

    return () => {
      window.removeEventListener("offline", onOffline);
      window.removeEventListener("online", onOnline);
      window.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  function componentWithProps(Component: any, props: any) {
    return function (matchProps: any) {
      return <Component {...props} {...matchProps} />;
    };
  }

  return (
    <QueryClientProvider client={queryClient}>
      <div className={"notranslate"}>
        {!isOffline ? (
          <>
            {!isServerDown ? (
              <Layout className={style.rootLayout} id="rootLayout">
                {loggedIn ? (
                  <Router>
                    <>
                      <NavTop setLoggedIn={setLoggedIn} />
                      <Layout>
                        <NavLeft setLoggedIn={setLoggedIn} setIsNavLeftVisible={setIsNavLeftVisible} />

                        <Layout className={style.contentLayout}>
                          <Switch>
                            <Route exact path="/home" component={Home} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/arbeitszeiten/:type?/:token?" component={Arbeitszeiten} />
                            {/* <Route exact path="/filial_manager" component={FilialManager} />*/}
                            <Route exact path="/datev/:type?/:token?" component={Datev} />
                            <Route exact path="/statistik/:statistikID/:navType?/:navToken?" component={Statistik} />
                            <Route exact path="/user_manager" component={UserManager} />
                            <Route
                              exact
                              path="/sollarbeitszeiten/:type?/:token?"
                              component={componentWithProps(SollArbeitszeiten, { isNavLeftVisible: isNavLeftVisible })}
                            />
                            <Route
                              exact
                              path="/planer/:token?"
                              component={componentWithProps(Planer, { isNavLeftVisible: isNavLeftVisible })}
                            />
                            <Redirect to="/home" from="/" />
                          </Switch>
                        </Layout>
                      </Layout>
                    </>
                  </Router>
                ) : null}

                {!loggedIn ? <Login setLoggedIn={setLoggedIn} /> : null}
              </Layout>
            ) : (
              <Content style={{ textAlign: "center", height: "50%", width: "100%" }}>
                <div style={{ height: "25%", width: "100%" }}></div>
                <img src={logo} alt="e-cut Connect" style={{ width: "25%" }} />
                <div style={{ height: "5%", width: "100%" }}></div>
                <Result
                  status="404"
                  title="Ups, der e-cut Server hat gerade keine Lust!"
                  extra={[
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <text style={{ textAlign: "center" }}>
                        <div>
                          Sobald wieder eine Verbindung zum e-cut Server besteht, wird sich diese Seite selbstständig
                          aktualisieren.
                        </div>
                      </text>
                    </div>,
                  ]}
                />
              </Content>
            )}
          </>
        ) : (
          <Content style={{ textAlign: "center", height: "50%", width: "100%" }}>
            <div style={{ height: "25%", width: "100%" }}></div>
            <img src={logo} alt="e-cut Connect" style={{ width: "25%" }} />
            <div style={{ height: "5%", width: "100%" }}></div>
            <Result
              status="500"
              title="Es besteht zur Zeit keine Verbindung zum Internet!"
              style={{ width: "100%" }}
              extra={[
                <div>
                  <text>
                    <div>
                      Sobald eine Verbindung zum Internet besteht, wird sich diese Seite selbstständig aktualisieren.
                    </div>
                  </text>
                </div>,
              ]}
            />
          </Content>
        )}
      </div>
    </QueryClientProvider>
  );
};

export default App;
