import { useQuery } from "react-query";
import { fetchData, getWertFromlocalStorage } from "../utils/functions";
import { APPIDENT } from "../constants";
import { APIArbeitszeiten } from "../types/apiTypes";
const pkg = require("../../package.json");

interface QueryParams {
  userToken: string;
  filialToken: string[];
  startDate: string;
  endDate: string;
}

const getQuery = ({ userToken, filialToken, startDate, endDate }: QueryParams) => ({
  query: `query {
    getArbeitszeiten(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: ${JSON.stringify(filialToken)},
        datum1: "${startDate}",
        datum2: "${endDate}") {
        header {
            success,
            errorCode,
            errorText
        },
        data {
            tabHeader {
                text,
                sortierbar,
                suchbar,
                stdSortierung,
                typ,
                ausrichtung,
                breiteProzent,
                statisch,
                mitZusatzinformationen,
                sichtbar
          },
          tabZeilen {
                spalten,
                spaltenProps
          }
        }
      }
    }`,
});

export const useArbeitszeiten = (queryParams: QueryParams, queryOptions = {}) => {
  if (getWertFromlocalStorage("loginInfo", "userToken") === "") queryOptions = { enabled: false };

  let queryObject = getQuery(queryParams);
  let { isLoading, error, data } = useQuery(
    ["arbeitszeiten", queryObject.query],
    () => fetchData(queryObject),
    queryOptions
  );

  const arbeitszeiten: APIArbeitszeiten = data?.data?.data?.getArbeitszeiten?.data ?? [];

  return { isLoading, error, arbeitszeiten };
};
