export enum GENERICTYPE_PARAMETER_MSM {
  GENERICTYPE_PARAMETER_MSM_MSM = 1,
  GENERICTYPE_PARAMETER_MSM_CONNECT = 0,
}

export enum GENERICTYPE_PARAMETER_LOCALDB {
  GENERICTYPE_PARAMETER_LOCALDB_LOCAL = 0,
  GENERICTYPE_PARAMETER_LOCALDB_SERVER = 1,
}

export enum GENERICTYPE_PARAMETER_ACTIVEMODUL {
  GENERICTYPE_PARAMETER_ACTIVEMODUL_CONNECT = "connect",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_DIENSTPLAN = "dienstplan",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_ARBEITSZEITNACHWEIS = "arbeitszeitnachweis",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_STATISTIK = "statistik",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_FORECAST = "forecast",
  GENERICTYPE_PARAMETER_ACTIVEMODUL_EINSTELLUNGEN = "einstellungen",
}
