import { Dictionary } from "@fullcalendar/core/internal";
import { APISollAZeitFilialTagAbwesenheit, APISollAZeitFilialTagArbeitszeit } from "./types/apiTypes";
import { typeKopiereZeitraum } from "./types/sollArbeitszeitenTypes";
import dayjs from "dayjs";
import { CalendarApi } from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/react";

type undoCacheType = {
  index: number;
  type: string;
  datum: string;
  maID: string;
  arbeitszeit: APISollAZeitFilialTagArbeitszeit;
  abwesenheit: APISollAZeitFilialTagAbwesenheit;
};

export let globalMemory = {
  tagKopiert: "",
  monatKopiert: "",
  wocheKopiert: "",
  tagKopiertQuellMA: "",
  monatKopiertQuellMA: "",
  wocheKopiertQuellMA: "",
  kopieTyp: "",
  loadingDienst: false,
  kopieTag: {} as typeKopiereZeitraum,
  kopieWoche: {} as typeKopiereZeitraum,
  kopieMonat: {} as typeKopiereZeitraum,
  detailData: {} as Dictionary,
  undoCache: [] as undoCacheType[],
  pause1Von: dayjs().minute(0).hour(0),
  pause1Bis: dayjs().minute(0).hour(0),
  pause2Von: dayjs().minute(0).hour(0),
  pause2Bis: dayjs().minute(0).hour(0),
  urlaubsanspruch: 0,
  token: [],
  urlaubsjahr: 0,
  maid: 0,
  ma: "",
};
