import React, { useCallback } from "react";
import { Button, Divider, Form, Input, InputNumber, Modal } from "antd";
import { fetchData, getWertFromlocalStorage } from "../../../utils/functions";
import {
  getQuerGetUrlaubsanspruch,
  getQuerSetUrlaubsanspruch,
  QueryParams,
  QueryParamsSet,
} from "../../../hooks/queriesUrlaubsanspruch";
import { globalMemory } from "../../../globals";

const IWUrlaubsanspruchmodal = (props: any) => {
  const closeModalAction = useCallback(
    (event) => {
      props.closeAction(false);
    },
    [props.closeAction]
  );
  /**
   *  Urlaubsanspruch zur APi schicken
   */
  const handleUAAbschicken = (values: any) => {
    props.closeAction(false);
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");
    let maid = globalMemory.maid;
    let filialToken: string[] = globalMemory.token;
    let jahr = globalMemory.urlaubsjahr;
    let urlaubsanspruch = values.urlaub;
    fetchData(
      getQuerSetUrlaubsanspruch({ userToken, filialToken, jahr, maid, urlaubsanspruch } as QueryParamsSet)
    ).then(function (response) {
      // TODO: Response ausgeben ob erfolgreich oder Fehler
      let temp = response.data?.data?.setUrlaubsanspruch;

      if (temp.header.success) {
        props.closeAction(false);
      }
    });
  };

  return (
    <Modal
      title={"Urlaubsanspruch " + globalMemory.ma + " für " + globalMemory.urlaubsjahr}
      width={400}
      open={props.displayModal}
      footer={false}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
    >
      <Divider></Divider>

      <Form
        name="basic"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 400 }}
        initialValues={{ remember: true }}
        onFinish={handleUAAbschicken}
        autoComplete="off"
      >
        <Form.Item
          label="Urlaub:"
          name="urlaub"
          style={{ marginBottom: "25px" }}
          initialValue={globalMemory.urlaubsanspruch}
        >
          <InputNumber
            defaultValue={"" + globalMemory.urlaubsanspruch}
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
          ></InputNumber>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={closeModalAction}
            style={{
              backgroundColor: "#f5f5f5",
              color: "#4096ff",
              left: 147,
              top: 30,
              marginLeft: "50px",
            }}
          >
            Abbrechen
          </Button>{" "}
          <Button
            type="primary"
            htmlType="submit"
            style={{
              position: "absolute",
              left: 300,
              top: 30,
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Ok
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IWUrlaubsanspruchmodal;
