import { APPIDENT } from "../constants";
import { typeSetSollArbeitszeitenTag } from "../types/sollArbeitszeitenTypes";
const pkg = require("../../package.json");

export interface QueryParams {
  userToken: string;
  filialToken: string[];
  jahr: number;
  maid: number;
}

export interface QueryParamsSet {
  userToken: string;
  filialToken: string[];
  jahr: number;
  maid: number;
  urlaubsanspruch: number;
}

export const getQuerGetUrlaubsanspruch = ({ userToken, filialToken, jahr, maid }: QueryParams) => ({
  query: `query  {
      getUrlaubsanspruch(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
       jahr: ${jahr},
        maid: ${maid},
        ) {

        header {
            success,
            errorCode,
            errorText
        },

        data {
            jahr,
            maid,
            urlaubsanspruch
        }
      }
      }
  `,
});

export const getQuerSetUrlaubsanspruch = ({ userToken, filialToken, jahr, maid, urlaubsanspruch }: QueryParamsSet) => ({
  query: `mutation   {
      setUrlaubsanspruch(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}",
        filialToken: "${filialToken}",
       jahr: ${jahr},
        maid: ${maid},
        urlaubsanspruch:${urlaubsanspruch},
        ) {

        header {
            success,
            errorCode,
            errorText
        },

        data {
   erfolgreich
            dbid
            ueberschrift
            infotext
        }
      }
      }
  `,
});
