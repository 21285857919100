import React, { useMemo, useState } from "react";

import { Layout, Typography, Breadcrumb, Modal, Button, Popover, Col, Result, Select, Row, Spin } from "antd";
import { Table } from "antd";

// import styleGlobal from "../Global.module.css";
import style from "./Arbeitszeiten.module.css";
import moment from "moment";

import { useParams } from "react-router";
import { useVerbuende } from "../../../hooks/useVerbuende";
import { useFilialen } from "../../../hooks/useFilialen";

import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../../../constants";
import {
  APIEnumDatepicker,
  APIFixed,
  APIHorAlignment,
  APITabHeader,
  APITabHeaderExtended,
  APITabSort,
  APIVarType,
} from "../../../types/apiTypes";
import { getWertFromlocalStorage, numberFormatRevers } from "../../../utils/functions";
import { StatistikSortConfig } from "../../../types/statisticTypes";
import { useStammdaten } from "../../../hooks/useStammdaten";
import { useArbeitszeiten } from "../../../hooks/useArbeitszeiten";
import IWMonthPicker from "../../uiElemente/IWDatePicker/IWDatePicker";

import { exportToCSV } from "../Datev/utils";
import dayjs from "dayjs";
import IWPdfButton from "../../uiElemente/IWPdfButton/IWPdfButton";
import { RowInput } from "jspdf-autotable";
import IWDatePicker from "../../uiElemente/IWDatePicker/IWDatePicker";
import { start } from "repl";

const { Content } = Layout;
const { Title } = Typography;

const Arbeitszeiten = () => {
  const { Option } = Select;
  const [aktFilialToken, setAktFilialToken] = useState("");
  const { type, token = "" } = useParams<{ type: string; token: string }>();

  const [startDate, setStartDate] = useState(DEFAULT_DATEPICKER_START_MOMENT.format());
  const [endDate, setEndDate] = useState(DEFAULT_DATEPICKER_END_MOMENT.hour(0).minute(0).second(0).format());

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [detailModalContent, setDetailModalContent] = useState("");

  /**
   * State für MA Selection
   */
  const [selectedData, setSelectedData] = useState("");
  /**
   * Gibt an welcher ArrayIndex der Tabellendaten genutzt wird - in dem Fall Index 0 für den MA-Namen
   */
  const selectRow = 0;

  let isVerbund = type === "verbund";
  let isFiliale = type === "filiale";
  let filialToken = token ? [token] : [];
  let alias = "";

  let outputDataCSV: string[][] = [];

  if (token !== aktFilialToken) {
    setAktFilialToken(token);
    //setStartDate(DEFAULT_DATEPICKER_START_MOMENT.format());
    //setEndDate(DEFAULT_DATEPICKER_END_MOMENT.hour(0).minute(0).second(0).format());
  }

  if (startDate.includes("Invalid")) {
    setStartDate(DEFAULT_DATEPICKER_START_MOMENT.format());
    setEndDate(DEFAULT_DATEPICKER_END_MOMENT.hour(0).minute(0).second(0).format());
  }

  // Verbünde und Filialen laden, wenn wir auf einer Verbund- bzw. Filialseite sind
  const { verbuende } = useVerbuende({ enabled: isVerbund });
  const { filialen } = useFilialen({ enabled: isFiliale });

  if (isVerbund) {
    const verbund = verbuende.find((v) => v.verbundToken === token);
    if (verbund) {
      filialToken = verbund.filialen;
      alias = verbund.alias;
    }
  }

  if (isFiliale) {
    const filiale = filialen.find((f) => f.filialToken === token);
    if (filiale) alias = filiale.alias;
  }

  const { stammdaten } = useStammdaten();

  // Tabellendaten von API holen
  // eslint-disable-next-line
  const { isLoading, arbeitszeiten } = useArbeitszeiten({
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
    filialToken: filialToken,
    startDate: startDate,
    endDate: endDate,
  },
      {
        staleTime: 500, // Caching 500ms
        cacheTime: 500
      }
  );

  // Tabellendaten generieren
  const arbeitszeitenRAW = arbeitszeiten;

  // Tabellen Header
  const tableDataHeader = useMemo(() => {
    const headerRAW = arbeitszeitenRAW.tabHeader;

    let arrHeader: object[] = [];

    if (headerRAW) {
      let dataCSV: string[] = [];

      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeaderExtended = headerRAW[i];

        let ausrichtung: string = "";
        if (spalte.statisch === APIFixed.APIFIXED_LEFT) ausrichtung = "left";
        else if (spalte.statisch === APIFixed.APIFIXED_RIGHT) ausrichtung = "right";

        const spaltenName = parseInt(spalte.text);

        let geraderWochentag: boolean = true;

        // Wochentagsspalte
        if (!isNaN(spaltenName) && spaltenName >= 1 && spaltenName <= 31) {
          // eslint-disable-next-line
          if (spaltenName & 1) geraderWochentag = false; // gerader Wochentag in auskommentierter Sektion in Verwendung
        }

        dataCSV.push(spalte.text);

        let actSpaltenObject = {
          align: "left",
          dataIndex: "",
          title: "",
          width: "15",
          defaultSortOrder: "",
          className: "oddEven datumsZelle",
          fixed: ausrichtung,
          render: (outO: any) => (
            <>
              {/*
              // Tooltip Beispiel
              <Tooltip title={"text + " > " + zusatzInfos"} color={"#108fea"}>
                <span onClick={() => showModal(outO.props)}>{outO.text}</span>
              </Tooltip>
              */}

              {spalte.mitZusatzinformationen ? (
                <Popover title="Details" content={<div dangerouslySetInnerHTML={{ __html: outO.props }}></div>}>
                  <span
                    style={{
                      color: outO.color,
                    }}
                  >
                    {outO.text}
                  </span>
                  {/*
                  // Modal Beispiel <span onClick={() => showModal(outO.props)}>{outO.text}</span>
                  */}
                  {/* geraderWochentag ? (
                    <span
                      style={
                        {
                          / * backgroundColor: "CornFlowerBlue" * /
                        }
                      }
                    >
                      {outO.text}
                    </span>
                  ) : (
                    <span>{outO.text}</span>
                  )*/}
                </Popover>
              ) : (
                <span>{outO.text}</span>
              )}
            </>
          ),
        };
        actSpaltenObject.title = spalte.text;
        actSpaltenObject.dataIndex = "spalte" + i;
        actSpaltenObject.width = spalte.breiteProzent + "px";

        if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_NUMBER) {
          // @ts-ignore weil optionaler Parameter
          actSpaltenObject["sorter"] = (a: { [x: string]: string }, b: { [x: string]: string }) =>
            // @ts-ignore weil optionaler Parameter
            numberFormatRevers(a["spalte" + i]["text"]) - numberFormatRevers(b["spalte" + i]["text"]);
        } else if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_DATE) {
          // @ts-ignore weil optionaler Parameter
          actSpaltenObject["sorter"] = (
            a: { [x: string]: moment.MomentInput },
            b: { [x: string]: moment.MomentInput }
          ) => moment(a["spalte" + i], "DD.MM.YYYY").unix() - moment(b["spalte" + i], "DD.MM.YYYY").unix();
        } else if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_STRING) {
          // @ts-ignore weil optionaler Parameter
          actSpaltenObject["sorter"] = (a: { [x: string]: string }, b: { [x: string]: any }) =>
            // @ts-ignore weil optionaler Parameter
            a["spalte" + i]["text"].localeCompare(b["spalte" + i]["text"]);
        }
        if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_RIGHT) {
          actSpaltenObject.align = "right";
        } else if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_CENTER) {
          actSpaltenObject.align = "center";
        }
        if (spalte.stdSortierung === APITabSort.APITABSORT_DESC) {
          actSpaltenObject.defaultSortOrder = "descend";
        } else if (spalte.stdSortierung === APITabSort.APITABSORT_ASC) {
          actSpaltenObject.defaultSortOrder = "ascend";
        }

        arrHeader.push(actSpaltenObject);
      }
      outputDataCSV.push(dataCSV);
    }
    return arrHeader;
    // eslint-disable-next-line
  }, [stammdaten, arbeitszeitenRAW]);

  // Tabellenzeilen
  const tableData = useMemo(() => {
    const tableRAW = arbeitszeitenRAW.tabZeilen;
    let arrZeilen: {}[] = [];
    if (tableRAW) {
      for (const apiStatistikTabZeile of tableRAW) {
        // Wenn keine MA Auswahl getroffen zeige alle oder wenn MA ausgewählt sind zeige nur die gewählten
        if (
          selectedData.length === 0 ||
          (selectedData.length !== 0 && selectedData.includes(apiStatistikTabZeile.spalten[selectRow]))
        ) {
          let zeileTemp: {} = {};
          let dataCSV: string[] = [];
          for (let i = 0; i < apiStatistikTabZeile.spalten.length; i++) {
            let zeile: string = apiStatistikTabZeile.spalten[i];
            dataCSV.push(zeile);
            let props: string =
              apiStatistikTabZeile.spaltenProps[i] !== ""
                ? apiStatistikTabZeile.spaltenProps[i]
                : "Keine An-/Abmeldung erfolgt.";
            let color: string = zeile === "00:00" ? "#BDBDBD" : "#000000";
            let identifier = "spalte" + i;
            // @ts-ignore
            zeileTemp[identifier] = { text: zeile, props: props, color: color };
          }
          outputDataCSV.push(dataCSV);

          arrZeilen.push(zeileTemp);
        }
      }
    }
    arrZeilen.sort(function (a, b) {
      // @ts-ignore
      let textA = a.spalte0.text;
      // @ts-ignore
      let textB = b.spalte0.text;
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    outputDataCSV.sort(function (a, b) {
      console.log(a);

      // @ts-ignore
      let textA = a[0];
      // @ts-ignore
      let textB = b[0];
      if (textA.includes("MA-Name") || textB.includes("MA-Name")) return 0;
      else return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return arrZeilen;
    // eslint-disable-next-line
  }, [stammdaten, arbeitszeitenRAW, selectedData]);

  /**
   * Options für Select aus den Mitarbeiternamen generieren
   */
  const selectData = useMemo(() => {
    const tableRAW = arbeitszeitenRAW.tabZeilen;
    let arrZeilen: {}[] = [];
    let arrNames: String[] = [];

    if (tableRAW) {
      for (const apiStatistikTabZeile of tableRAW) {
        arrNames.push(apiStatistikTabZeile.spalten[selectRow]);
      }
      arrNames.sort();
      for (const zeileName of arrNames) {
        arrZeilen.push(
          <Option key={"" + zeileName} value={"" + zeileName}>
            {"" + zeileName}
          </Option>
        );
      }
    }
    return arrZeilen;
    // eslint-disable-next-line
  }, [stammdaten, arbeitszeitenRAW]);

  /**
   * bei jedem MA select wird der State aktualisiert
   */
  const handleChangeSelect = (value: any) => {
    setSelectedData(value);
  };

  /*
  // Tabellenzeilen (spaltenProps)
  const tableDataProps = useMemo(() => {
    const tableRAW = arbeitszeitenRAW.tabZeilen;

    let arrZeilen: {}[] = [];

    if (tableRAW) {
      for (const apiStatistikTabZeile of tableRAW) {
        let zeileTemp: {} = {};

        for (let i = 0; i < apiStatistikTabZeile.spalten.length; i++) {
          let zeile: string = apiStatistikTabZeile.spaltenProps[i];
          let identifier = "spalte" + i;
          // @ts-ignore
          zeileTemp[identifier] = zeile;
        }
        arrZeilen.push(zeileTemp);
      }
    }
    return arrZeilen;
  }, [stammdaten, arbeitszeitenRAW]);
   */

  // Umsetzen von Monats/Jahres Picker
  const handleChangeDate = (props: any) => {
    let momentAsDayJS = dayjs(props);
    console.log(props);
    const month = momentAsDayJS.toDate().getMonth() + 1; // +1, weil sonst 0 = Jan, 11 = Dez
    const year = momentAsDayJS.toDate().getFullYear();

    const startOfMonth = moment(year + "-" + month + "-15")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = moment(year + "-" + month + "-15")
      .endOf("month")
      .format("YYYY-MM-DD");
    setStartDate(moment(startOfMonth).format());
    setEndDate(moment(endOfMonth).hour(0).format());

    // Tabellendaten von API holen
    /*
    const { isLoading, arbeitszeiten } = useArbeitszeiten({
      userToken: getWertFromlocalStorage("loginInfo", "userToken"),
      filialToken: filialToken,
      startDate: startDate,
      endDate: endDate,
    });
     */
  };

  // TODO  schauen ob das schöner geht ohne die eslint disable
  let runOnceBoolean = true;
  // eslint-disable-next-line
  const runOnce = useMemo(() => {
    const dateForInitialChangeDate = new Date();
    let objectForInitialChangeDate = { _d: dateForInitialChangeDate };
    handleChangeDate(objectForInitialChangeDate);
    // eslint-disable-next-line
    runOnceBoolean = false;
    // eslint-disable-next-line
  }, [(runOnceBoolean = true)]);

  // bei Öffnen von Detail Modal
  // eslint-disable-next-line
  const showModal = (text: string) => {
    setDetailModalContent(text);
    setIsModalVisible(true);
  };

  // bei Schließen von Detail Modal über OK
  const handleOk = () => {
    setIsModalVisible(false);
  };

  // bei Schließen von Detail Modal über Abbrechen
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const getSortConfig = useMemo(() => {
    const headerRAW = arbeitszeitenRAW.tabHeader;
    let searchID: StatistikSortConfig = {
      id: 0,
      order: APITabSort.APITABSORT_NONE,
      type: APIVarType.APIVARTYPE_STRING,
    };

    if (headerRAW !== undefined) {
      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeaderExtended = headerRAW[i];
        if (spalte.stdSortierung !== APITabSort.APITABSORT_NONE) {
          searchID.id = i;
          searchID.order = spalte.stdSortierung;
          searchID.type = spalte.typ;
        }
      }
    }
    return searchID;
    // eslint-disable-next-line
  }, [stammdaten, arbeitszeitenRAW]);

  // Tabellendaten generieren für PDF
  // eslint-disable-next-line
  const tableDataArray = useMemo(() => {
    const tableRAW = arbeitszeitenRAW.tabZeilen;
    let arrZeilen: string[][] = [];
    //let maxSpalten = 0;
    if (tableRAW !== undefined) {
      for (const apiStatistikTabZeile of tableRAW) {
        let zeileTemp: string[] = [];
        for (let i = 0; i < apiStatistikTabZeile.spalten.length; i++) {
          let zeile: string = apiStatistikTabZeile.spalten[i];
          zeileTemp.push(zeile);
        }
        //maxSpalten = apiStatistikTabZeile.spalten.length;
        arrZeilen.push(zeileTemp);
      }
    }

    if (getSortConfig.order !== APITabSort.APITABSORT_NONE) {
      if (getSortConfig.type === APIVarType.APIVARTYPE_NUMBER) {
        arrZeilen.sort((a, b) => numberFormatRevers(a[getSortConfig.id]) - numberFormatRevers(b[getSortConfig.id]));
      } else if (getSortConfig.type === APIVarType.APIVARTYPE_DATE) {
        arrZeilen.sort(
          (a, b) => moment(a[getSortConfig.id], "DD.MM.YYYY").unix() - moment(b[getSortConfig.id], "DD.MM.YYYY").unix()
        );
      } else {
        arrZeilen.sort((a, b) => a[getSortConfig.id].localeCompare(b[getSortConfig.id]));
      }

      if (getSortConfig.order === APITabSort.APITABSORT_DESC) {
        arrZeilen.reverse();
      }
    }

    arrZeilen.sort(function (a, b) {
      console.log(a);
      // @ts-ignore
      let textA = a[0];
      // @ts-ignore
      let textB = b[0];
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    return arrZeilen;
    // eslint-disable-next-line
  }, [stammdaten, arbeitszeitenRAW]);

  // Tabellenheader generieren für PDF
  // eslint-disable-next-line
  const tableHeaderArray = useMemo(() => {
    const headerRAW = arbeitszeitenRAW.tabHeader;
    let arrHeader: string[] = [];
    if (headerRAW !== undefined) {
      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeader = headerRAW[i];
        arrHeader.push(spalte.text);
      }
    }

    return arrHeader;
    // eslint-disable-next-line
  }, [stammdaten, arbeitszeitenRAW]);
  // eslint-disable-next-line
  const pdfStyleObject = useMemo(() => {
    const headerRAW = arbeitszeitenRAW.tabHeader;
    let arrHeader: any = {};
    if (headerRAW !== undefined) {
      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeader = headerRAW[i];
        let alignString = "left";
        if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_CENTER) alignString = "center";
        else if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_RIGHT) alignString = "right";
        arrHeader[i] = { halign: alignString };
      }
    }
    return arrHeader;
    // eslint-disable-next-line
  }, [stammdaten, arbeitszeitenRAW]);

  if (type) {
    return (
      <Content className="global-layout-background global-content">
        <Spin tip="Loading" size="large" spinning={isLoading}>
          <Breadcrumb style={{ margin: "16px 0" }}>
            <Breadcrumb.Item>{type === "all" ? "Alle Filialen" : alias}</Breadcrumb.Item>
          </Breadcrumb>

          <div className={style.headline}>
            <Title level={2}>Arbeitszeitnachweis</Title>
          </div>
          <div className={style.headerButtons}>
            <Button
              type="primary"
              onClick={() =>
                exportToCSV(
                  "Ist-Zeiten Export " +
                    moment(startDate).format("DD.MM.YYYY") +
                    " - " +
                    moment(endDate).format("DD.MM.YYYY") +
                    ".csv",
                  outputDataCSV
                )
              }
            >
              Daten exportieren
            </Button>
          </div>

          <div className={style.contentBlock} id="content_block">
            <Table
              size="small"
              columns={tableDataHeader}
              dataSource={tableData}
              pagination={{
                position: ["bottomCenter"],
                defaultPageSize: 50,
              }}
              scroll={{ x: "calc(100vw)", y: "calc(100vh - 430px)" }}
            />
          </div>

          <Row justify="start">
            <Col lg={6} xl={5} xxl={3} style={{ textAlign: "start", marginLeft: "8px" }}>
              Mitarbeiter:&nbsp;
            </Col>
            <Col lg={9} xl={6} xxl={5}>
              <div>
                <Select
                  mode="multiple"
                  allowClear={true}
                  style={{
                    marginBottom: "5px",
                    marginLeft: "8px",
                    width: "450px",
                  }}
                  placeholder="Bitte wählen Sie"
                  onChange={handleChangeSelect}
                >
                  {selectData}
                </Select>
              </div>
            </Col>
          </Row>
          {/* TODO : Default Date setzten geht nicht */}
          <Row justify="start">
            <IWMonthPicker onClick={handleChangeDate} range="month" defaultDate={dayjs(startDate)} />
          </Row>
          <div>
            {/**
              <Col style={{ marginLeft: "55px" }}>
                <IWPdfButton
                  tableDataArray={tableDataArray as RowInput[]}
                  tableHeaderArray={tableHeaderArray as string[]}
                  pdfHeader={
                    `Arbeitszeiten vom ${moment(startDate).format("DD.MM.YYYY")} bis ${moment(endDate).format(
                      "DD.MM.YYYY"
                    )} - ` + (type === "all" ? "Alle Filialen" : alias)
                  }
                  fileName={`Arbeitszeiten_${moment(startDate).format("DD_MM_YYYY")}-${moment(endDate).format(
                    "DD_MM_YYYY"
                  )}_${type === "all" ? "Alle_Filialen" : alias}`}
                  theme={"striped"}
                  headStyle={{
                    halign: "center",
                    fillColor: "CornFlowerBlue",
                    lineWidth: 1,
                    lineColor: "CornFlowerBlue",
                  }}
                  columnStyle={pdfStyleObject}
                  showPage={true}
                  showDate={true}
                />
              </Col>
          */}
          </div>
        </Spin>
        <Modal
          title="Details"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button type="primary" onClick={handleCancel}>
              OK
            </Button>,
          ]}
        >
          {detailModalContent}
        </Modal>
      </Content>
    );
  }

  return (
    <Content className="global-layout-background global-content">
      <div className={style.headline}>
        <Title level={2}>Arbeitszeitnachweis</Title>
        <Result
          title="Noch kein Verbund / Filiale ausgewählt"
          extra={[
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <text style={{ textAlign: "left" }}>
                <div>1. Bitte zunächst links eine Auswahl treffen (Alle Filialen, Verbund oder einzelne Filiale)</div>
                <div>2. Betrachtungszeitraum anpassen</div>
              </text>
            </div>,
          ]}
        />
      </div>
    </Content>
  );
};

export default Arbeitszeiten;
