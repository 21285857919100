import React, { useMemo, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import styled from "@emotion/styled";
import { Content } from "antd/lib/layout/layout";
import { useStammdaten } from "../../../hooks/useStammdaten";
import { fetchData, getFilialnameFromID, getMANameFromID, getWertFromlocalStorage } from "../../../utils/functions";
import { getQueryGetSollArbeotszeitenSingleMA, useSollArbeitszeiten } from "../../../hooks/useSollArbeitszeiten";
import { getQuerGetUrlaubsanspruch, QueryParams } from "../../../hooks/queriesUrlaubsanspruch";
import { useFilialen } from "../../../hooks/useFilialen";
import { hideAll } from "tippy.js";
import {
  APIEnumDatepicker,
  APIEnumTurnusTyp,
  APIEnumZeitKontext,
  APIEnumZeitTyp,
  APIErfolgRueckmeldung,
  APIGetSollArbeitszeiten,
  APISollAZeitFilialTagAbwesenheit,
  APISollAZeitFilialTagArbeitszeit,
} from "../../../types/apiTypes";
import { Button, Divider, Dropdown, Form, Modal, Result, Row, Select, Space, Spin } from "antd";

import {
  sollArbeitszeitenDaten,
  sollArbeitszeitenMitarbeiter,
  sollArbeitszeitenMitarbeiterFilialen,
  sollArbeitszeitenTag,
  typeDeleteZeitraum,
  typeKopiereZeitraum,
  typeModalBestaetigung,
  typeModalBestaetigungCopy,
  typeSetAbwesenheit,
  typeSetSollArbeitszeitenTag,
} from "../../../types/sollArbeitszeitenTypes";
import dayjs from "dayjs";
import { useParams } from "react-router";
import { useVerbuende } from "../../../hooks/useVerbuende";
import IWDatePicker from "../../uiElemente/IWDatePicker/IWDatePicker";
import { Moment } from "moment";
import { DEFAULT_DATEPICKER_END_MOMENT, DEFAULT_DATEPICKER_START_MOMENT } from "../../../constants";
import style from "../Datev/Datev.module.css";
import { EventMountArg } from "@fullcalendar/core";
import interactionPlugin from "@fullcalendar/interaction";
import { Dictionary, EventImpl } from "@fullcalendar/core/internal";

import { tippy } from "@tippyjs/react";
import "../../../tippy_ecut.css";
import {
  ArrowRightOutlined,
  CalendarTwoTone,
  CopyTwoTone,
  EditTwoTone,
  EyeTwoTone,
  SmileTwoTone,
  SnippetsTwoTone,
} from "@ant-design/icons";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { globalMemory } from "../../../globals";
import {
  getQueryDeleteZeitraumAbwesenheit,
  getQueryDeleteZeitraumArbeitszeit,
  getQueryKopiereZeitraum,
  getQuerySetAbwesenheit,
  getQuerySetSollArbeitszeit,
} from "../../../hooks/queriesDienstplaene";

import "../SollArbeitszeiten/SollArbeitszeiten.module.css";
import { ColCellMountArg, ColHeaderMountArg, ResourceApi } from "@fullcalendar/resource";
import IWUrlaubsanspruchmodal from "./IWUrlaubsanspruchmodal";
import IWAbwesenheitsmodal from "./IWAbwesenheitsmodal";
import IWArbeitszeitenmodal from "./IWArbeitszeitenmodal";
import { isCompanyAdministrator } from "../../../utils/functionsSecurity";
import { cmdParameter } from "../../App/App";
import { GENERICTYPE_PARAMETER_ACTIVEMODUL } from "../../../types/genericTypes";
import { useLocation } from "react-router-dom";

const SollArbeitszeiten = (props: any) => {
  let calendarRef = React.createRef<FullCalendar>();

  const stammdaten = useStammdaten();
  const filialen = useFilialen().filialen;

  const initEvent: Dictionary = {};
  const itemsDefault: ItemType[] = [
    {
      key: "1",
      label: <div>dummy menu item</div>,
    },
  ];

  /**
   * includes fuer dayJS Sonderfunktionen (Kalenderwoche)
   */
  let weekOfYear = require("dayjs/plugin/weekOfYear");
  dayjs.extend(weekOfYear);

  /**
   * Generalle States
   */
  const [aktFilialToken, setAktFilialToken] = useState("");

  const [neuerMAFA, setNeuerMAFA] = useState(0);
  const [aktCalendarView, setAktCalendarView] = useState("Monatsansicht");
  const [startDate, setStartDate] = useState(DEFAULT_DATEPICKER_START_MOMENT.format("YYYY-MM-DD") + "T00:00:00.000Z");
  const [endDate, setEndDate] = useState(DEFAULT_DATEPICKER_END_MOMENT.format("YYYY-MM-DD") + "T00:00:00.000Z");
  const [detailData, setDetailData] = useState(initEvent);
  const [dateData, setDateData] = useState({
    startDateData: DEFAULT_DATEPICKER_START_MOMENT.startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
    endDateData: DEFAULT_DATEPICKER_END_MOMENT.endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
  });
  /**
   * States fuer das Contextmenu
   */
  const [displayModalMenuItems, setDisplayModalMenuItems] = useState(itemsDefault);
  const [displayModalMenu, setDisplayModalMenu] = useState(false);
  const [displayModalMenuPosX, setDisplayModalMenuPosX] = useState(-500);
  const [displayModalMenuPosY, setDisplayModalMenuPosY] = useState(-500);

  const [displayUrlaubsanspruch, setDisplayUrlaubsanspruch] = useState(0);

  /**
   * States fuer Modals MA Filiale hinzufpgen
   */
  const [displayModalSelectMAFA, setDisplayModalSelectMAFA] = useState(false);

  /**
   * States fuer Modals Abwesenheiten und Arbeitszeiten
   */
  const [displaySettingsModalArbeitszeit, setDisplaySettingsModalArbeitszeit] = useState(false);
  const [displaySettingsModalAbwesenheit, setDisplaySettingsModalAbwesenheit] = useState(false);
  const [displayDetailPause, setDisplayDetailPause] = useState(false);
  const [displayDetailBisEndlosAbwesenheit, setDisplayDetailBisEndlosAbwesenheit] = useState(false);
  const [displayDetailBisEndlosArbeitszeit, setDisplayDetailBisEndlosArbeitszeit] = useState(false);
  const [displayDetailEditZeitKontext, setDisplayDetailEditZeitKontext] = useState(false);
  const [displayAbwesenheitSelectDisabled, setDisplayAbwesenheitSelectDisabled] = useState(true);
  const [displayAbwesenheitSelectSchule, setDisplayAbwesenheitSelectSchule] = useState(false);
  const [displayAbwesenheitSelect, setDisplayAbwesenheitSelect] = useState("");

  const [displayAbwesenheitSelectMAFA, setDisplayAbwesenheitSelectMAFA] = useState(false);
  const [displayNewEvent, setDisplayNewEvent] = useState(false);
  const [displayFirstOpen, setDisplayFirstOpen] = useState(true);
  const [isUrlaubOderKrank, setisUrlaubOderKrank] = useState(false);
  /**
   * States fuer das Bestaetigungsmodal
   */

  const [displayModalBestaetigung, setDisplayModalBestaetigung] = useState(false);
  const [displayModalBestaetigungValues, setDisplayModalBestaetigungValues] = useState({});

  const [displayModalBestaetigungKopieren, setDisplayModalBestaetigungKopieren] = useState(false);
  const [displayModalBestaetigungKopierenText, setDisplayModalBestaetigungKopierenText] = useState("");
  const [displayModalBestaetigungContent, setDisplayModalBestaetigungContent] = useState({} as typeModalBestaetigung);
  const [displayModalCopyContent, setDisplayModalCopyContent] = useState({} as typeModalBestaetigungCopy);
  const [displayModalBestaetigungIsDelete, setDisplayModalBestaetigungIsDelete] = useState(false);

  const [displayModalUrlaubsanspruch, setDisplayModalUrlaubsanspruch] = useState(false);
  const [displayModalErrorContent, setDisplayModalErrorContent] = useState({} as typeModalBestaetigung);
  const [displayModalError, setDisplayModalError] = useState(false);
  const [isBackDelete, setIsBackDelete] = useState(false);
  const [okButtonColor, setOKButtonColor] = useState("#4096ff");
  /**
   * Farbwerte fuer Kalender
   */
  const colorArbeitstag = "#8EB8F0";
  const colorUrlaubstag = "#43BF99";
  const colorKrankheitstag = "#FC686F";
  const colorSchule = "#8085D9";
  const colorSonstiges = "#8085D9";
  const colorWochenende = "#BBBBBB";
  const colorFeiertag = "#BBBBBB";
  const colorRuhetag = "#A8DBC2";
  const colorLeerEvent = "#DDDDDD";
  //const colorSumme = "#DDDDDD ";
  /*
  const colorArbeitstag = "#81aee6";
  const colorUrlaubstag = "#3eab63";
  const colorKrankheitstag = "#b43b35";
  const colorSchule = "#b99930";
  const colorSonstiges = "#b99930";
  const colorWochenende = "#697b95";
  const colorFeiertag = "#697b95";
  const colorRuhetag = "#697b95";
  const colorLeerEvent = "#e1eaf9";*/
  const colorSumme = "#599bdf";

  const { type, token = "" } = useParams<{ type: string; token: string }>();

  let isVerbund = type === "verbund";
  let isAlle = type === "all";
  let filialToken = token ? [token] : [];

  /**
   * Simpler reload der Daten
   */
  const reloadData = () => {
    let start: string =
      "" +
      dayjs(dateData.startDateData).format("YYYY-MM-DD") +
      "T00:00:" +
      dayjs(dateData.startDateData).add(1, "second").format("ss") +
      ".000Z";

    let end: string = "" + dayjs(dateData.endDateData).subtract(1, "second").format("YYYY-MM-DD") + "T00:00:00.000Z";
    setDateData({
      startDateData: start,
      endDateData: end,
    });
  };

  let aktDate = dayjs(DEFAULT_DATEPICKER_START_MOMENT.format("YYYY-MM-DD")).startOf("month");

  //aktDate = aktDate.add(1, "months");

  /**
   * beim Wechsel von Filialen folgende Sachen wieder auf default setzten
   */
  if (token !== aktFilialToken) {
    setAktFilialToken(token);
    setStartDate(DEFAULT_DATEPICKER_START_MOMENT.format());
    setEndDate(DEFAULT_DATEPICKER_END_MOMENT.format());
    document.removeEventListener("click", () => console.log("Listener Removed"));
  }

  /**
   * Mitarbeiter fuer Select generieren
   */
  const generiereMASelect = () => {
    let returnMA: {}[] = [];

    let aktFA = stammdaten.stammdaten.mitarbeiterJeFiliale[0];
    for (let a = 0; a < aktFA.mitarbeiter.length; a++) {
      let aktMA = aktFA.mitarbeiter[a];
      let maName = aktMA.name;
      if (!aktMA.del) returnMA.push({ value: maName + "_+_" + aktMA.id, label: maName });
    }
    return returnMA;
  };

  /**
   * Filialen fuer Select generieren
   */
  const generiereFilialSelect = () => {
    let returnFA: {}[] = [];
    for (let a = 0; a < filialen.length; a++) {
      let aktFiliale = filialen[a];
      let faID = aktFiliale.filialToken;
      let faName = aktFiliale.name;
      returnFA.push({ value: faID, label: faName });
    }
    return returnFA;
  };

  const { verbuende } = useVerbuende({ enabled: isVerbund });

  if (isVerbund) {
    const verbund = verbuende.find((v) => v.verbundToken === token);
    if (verbund) {
      filialToken = verbund.filialen;
    }
  }

  if (isAlle) {
    filialToken = [];
  }

  /**
   * Daten holen
   */

  let { isLoading, sollarbeitszeiten } = useSollArbeitszeiten({
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
    filialToken: filialToken,
    startDate: dateData.startDateData,
    endDate: dateData.endDateData,
  });

  const getSollArbeitszeitenFuerMitarbeiter = (filialid: [], maid: number, usage: string) => {
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");

    let returnData: APIGetSollArbeitszeiten[] = [] as APIGetSollArbeitszeiten[];

    let startDate = dateData.startDateData;
    let endDate = dateData.endDateData;
    fetchData(getQueryGetSollArbeotszeitenSingleMA({ userToken, filialid, startDate, endDate, maid })).then(function (
      response
    ) {
      // TODO: Response ausgeben ob erfolgreich oder Fehler
      let temp = response.data?.data?.getSollArbeitszeiten;
      if (temp.header.success) {
        returnData = temp.data;

        if (!usage.includes("addMA")) {
          if (filialid.length >= 1) {
            // @ts-ignore
            removeEventsOfMA(filialid[0], maid);
          }
        }

        if (usage.includes("addMA")) {
          convertToKalenderTypeExtended(returnData);
        }
      }
    });
  };

  /**
   * Lösche Events für MA / FI bei response success
   */
  const removeEventsOfMA = (filialid: string, maid: string) => {
    let arrEventApiItems: ResourceApi[] = calendarRef.current?.getApi().getResources() as ResourceApi[];
    let aktFilialID = maid + "" + filialid;

    let filteredResources = arrEventApiItems.filter(function (el) {
      return el.id === aktFilialID || el.id === maid;
    });
    let filteredEvents = calendarRef.current
      ?.getApi()
      .getEvents()
      .filter(function (el) {
        return el.getResources()[0].id === maid || el.getResources()[0].id === aktFilialID;
      });
    if (filteredEvents?.length !== undefined) {
      for (const singleEvent of filteredEvents) {
        singleEvent.remove();
      }
    }
    if (filteredResources?.length !== undefined) {
      for (const singleResource of filteredResources) {
        singleResource.setExtendedProp("sumA", "500");
        //  console.log(singleResource.extendedProps);
      }
    }
    // console.log(filteredEvents);
  };

  /**
   * auf vorherigen Monat  umschalten
   */
  /*const umschaltenAufVorherigenMonat = () => {
    let start: Moment = moment(dateData.startDateData).subtract(1, "month");
    handleChangeDate(start);
  };*/

  /**
   * Konverter in sollArbeitszeitentypes
   */
  const convertToKalenderType: sollArbeitszeitenDaten = useMemo(() => {
    isLoading = true;

    //Erzeugen eines sollArbeitszeitenTypes-Objekt
    let returnSollArbeitszeiten: sollArbeitszeitenDaten = {} as sollArbeitszeitenDaten;
    if (sollarbeitszeiten !== undefined && sollarbeitszeiten !== null && sollarbeitszeiten.length >= 1) {
      returnSollArbeitszeiten.mitarbeiter = [];
      // *Erzeugen der Kalenderdaten auf den Filialen
      //  Schleife ueber alle Mitarbeiter
      for (let a = 0; a < sollarbeitszeiten.length; a++) {
        let aktMA: sollArbeitszeitenMitarbeiter = {} as sollArbeitszeitenMitarbeiter;
        aktMA.mitarbeiterId = sollarbeitszeiten[a].maid;

        aktMA.mitarbeiterName = "" + getMANameFromID(aktMA.mitarbeiterId, stammdaten.stammdaten.mitarbeiterJeFiliale);
        aktMA.filialen = [];
        aktMA.sumArbeitszeit = "" + sollarbeitszeiten[a].sollAZeitSumme.arbeitszeit;
        aktMA.sumKrankheit = "" + sollarbeitszeiten[a].sollAZeitSumme.krank;
        aktMA.sumUrlaub = "" + sollarbeitszeiten[a].sollAZeitSumme.urlaub;
        //    Schleife ueber alle Filialen
        for (let b = 0; b < sollarbeitszeiten[a].sollAZeitFilialen.length; b++) {
          let aktFiliale: sollArbeitszeitenMitarbeiterFilialen = {} as sollArbeitszeitenMitarbeiterFilialen;
          aktFiliale.filialtoken = "" + sollarbeitszeiten[a].sollAZeitFilialen[b].filialToken;
          aktFiliale.filialname =
            "" + getFilialnameFromID(sollarbeitszeiten[a].sollAZeitFilialen[b].filialToken, filialen);
          aktFiliale.tage = [];
          aktFiliale.sumArbeitszeit = "" + sollarbeitszeiten[a].sollAZeitFilialen[b].sollAZeitSumme.arbeitszeit;
          aktFiliale.sumKrankheit = "" + sollarbeitszeiten[a].sollAZeitFilialen[b].sollAZeitSumme.krank;
          aktFiliale.sumUrlaub = "" + sollarbeitszeiten[a].sollAZeitFilialen[b].sollAZeitSumme.urlaub;
          //      Schleife ueber jeden Tag

          for (let c = 0; c < sollarbeitszeiten[a].sollAZeitFilialen[b].sollAZeitFilialTage.length; c++) {
            let aktTag: sollArbeitszeitenTag = {} as sollArbeitszeitenTag;
            aktTag.abweseneheitszeiten = [];
            let tempTag = sollarbeitszeiten[a].sollAZeitFilialen[b].sollAZeitFilialTage[c];
            aktTag.dataJahr = "" + dayjs(tempTag.datum).year();
            aktTag.dataMonat =
              ("" + dayjs(tempTag.datum).add(1, "months").month()).length < 2
                ? "0" + dayjs(tempTag.datum).add(1, "months").month()
                : "" + dayjs(tempTag.datum).add(1, "months").month();
            if (aktTag.dataMonat.includes("00")) {
              aktTag.dataMonat = "12";
            }
            aktTag.dataTag =
              ("" + dayjs(tempTag.datum).date()).length < 2
                ? "0" + dayjs(tempTag.datum).date()
                : "" + dayjs(tempTag.datum).date();
            //          Schleife ueber alle Arbeitszeiten, nimm nur die erste und erzeuge einen Kalendereintrag dafuer
            //          Schleife ueber alle Abweseneheitszeiten
            //            Fuer jeden Abwesenheitstyp, nimm nur den ersten und erzeuge einen Kalendereintrag dafuer
            if (tempTag.arbeitszeit[0] != null) {
              aktTag.arbeitsZeit = tempTag.arbeitszeit[0];
            }
            aktTag.anzeigeZeitTypAktiv = tempTag.anzeigeZeitTypAktiv;
            aktTag.anzeigeZeitTyp = tempTag.anzeigeZeitTyp;
            for (let d = 0; d < tempTag.abwesenheit.length; d++) {
              aktTag.abweseneheitszeiten.push(tempTag.abwesenheit[d]);

              for (let f = 0; f < tempTag.abwesenheit.length; f++) {
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.KRANK) aktFiliale.hasKrankheit = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.URLAUB) aktFiliale.hasUrlaub = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.FEIERTAG) aktFiliale.hasFeiertag = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.SCHULE) aktFiliale.hasSchule = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.SONSTIGES) aktFiliale.hasSonstiges = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.WOCHENENDE) aktFiliale.hasWochenende = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.RUHETAG) aktFiliale.hasRuhetag = true;
              }
            }
            // * Summeneintraege auf dem MA generieren
            for (let MAc = 0; MAc < sollarbeitszeiten[a].sollAZeitMATage.length; MAc++) {
              if (sollarbeitszeiten[a].sollAZeitMATage[MAc].datum.includes(tempTag.datum)) {
                let aktAnzeigeTyp = sollarbeitszeiten[a].sollAZeitMATage[MAc].anzeigeZeitTyp;
                let aktAnzeigeSummen = sollarbeitszeiten[a].sollAZeitMATage[MAc].sollAZeitSumme;
                let aktAnzeigeSumme = "";
                if (aktAnzeigeTyp.includes(APIEnumZeitTyp.ARBEITSZEIT)) {
                  aktAnzeigeSumme = aktAnzeigeSummen.arbeitszeit;
                  aktTag.sumBemerkung = "";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.SCHULE)) {
                  aktAnzeigeSumme = "SCH";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.SONSTIGES)) {
                  aktAnzeigeSumme = "SONST";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.URLAUB)) {
                  aktAnzeigeSumme = "U";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.KRANK)) {
                  aktAnzeigeSumme = "K";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.FEIERTAG)) {
                  aktAnzeigeSumme = "F";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.WOCHENENDE)) {
                  aktAnzeigeSumme = "W";
                }
                aktTag.sumTag = aktAnzeigeSumme;
              }
            }
            aktFiliale.tage.push(aktTag);
          }
          aktMA.filialen.push(aktFiliale);
        }
        returnSollArbeitszeiten.mitarbeiter.push(aktMA);
      }
    }
    isLoading = false;

    return returnSollArbeitszeiten;
  }, [sollarbeitszeiten]);

  /**
   * Einfügen von Hinzugefügten MA und Filialen
   */
  const convertToKalenderTypeExtended = (getSollArbeitszeitenFuerMitarbeiter: APIGetSollArbeitszeiten[]) => {
    let boolIsPushed = false;
    if (
      getSollArbeitszeitenFuerMitarbeiter !== undefined &&
      getSollArbeitszeitenFuerMitarbeiter !== null &&
      getSollArbeitszeitenFuerMitarbeiter.length >= 1
    ) {
      // *Erzeugen der Kalenderdaten auf den Filialen
      //  Schleife ueber alle Mitarbeiter
      for (let a = 0; a < getSollArbeitszeitenFuerMitarbeiter.length; a++) {
        let aktMA: sollArbeitszeitenMitarbeiter = {} as sollArbeitszeitenMitarbeiter;
        let mitarbeiterGot: APIGetSollArbeitszeiten = getSollArbeitszeitenFuerMitarbeiter[a];

        aktMA.mitarbeiterId = mitarbeiterGot.maid;

        aktMA.mitarbeiterName = "" + getMANameFromID(aktMA.mitarbeiterId, stammdaten.stammdaten.mitarbeiterJeFiliale);
        aktMA.filialen = [];
        aktMA.sumArbeitszeit = "" + mitarbeiterGot.sollAZeitSumme.arbeitszeit;
        aktMA.sumKrankheit = "" + mitarbeiterGot.sollAZeitSumme.krank;
        aktMA.sumUrlaub = "" + mitarbeiterGot.sollAZeitSumme.urlaub;
        //    Schleife ueber alle Filialen
        for (let b = 0; b < getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitFilialen.length; b++) {
          let aktFiliale: sollArbeitszeitenMitarbeiterFilialen = {} as sollArbeitszeitenMitarbeiterFilialen;
          aktFiliale.filialtoken = "" + getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitFilialen[b].filialToken;
          aktFiliale.filialname =
            "" + getFilialnameFromID(getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitFilialen[b].filialToken, filialen);
          aktFiliale.tage = [];
          aktFiliale.sumArbeitszeit =
            "" + getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitFilialen[b].sollAZeitSumme.arbeitszeit;
          aktFiliale.sumKrankheit =
            "" + getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitFilialen[b].sollAZeitSumme.krank;
          aktFiliale.sumUrlaub = "" + getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitFilialen[b].sollAZeitSumme.urlaub;
          //      Schleife ueber jeden Tag

          for (
            let c = 0;
            c < getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitFilialen[b].sollAZeitFilialTage.length;
            c++
          ) {
            let aktTag: sollArbeitszeitenTag = {} as sollArbeitszeitenTag;
            aktTag.abweseneheitszeiten = [];
            let tempTag = getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitFilialen[b].sollAZeitFilialTage[c];
            aktTag.dataJahr = "" + dayjs(tempTag.datum).year();
            aktTag.dataMonat =
              ("" + dayjs(tempTag.datum).add(1, "months").month()).length < 2
                ? "0" + dayjs(tempTag.datum).add(1, "months").month()
                : "" + dayjs(tempTag.datum).add(1, "months").month();
            if (aktTag.dataMonat.includes("00")) {
              aktTag.dataMonat = "12";
            }
            aktTag.dataTag =
              ("" + dayjs(tempTag.datum).date()).length < 2
                ? "0" + dayjs(tempTag.datum).date()
                : "" + dayjs(tempTag.datum).date();
            //          Schleife ueber alle Arbeitszeiten, nimm nur die erste und erzeuge einen Kalendereintrag dafuer
            //          Schleife ueber alle Abweseneheitszeiten
            //            Fuer jeden Abwesenheitstyp, nimm nur den ersten und erzeuge einen Kalendereintrag dafuer
            if (tempTag.arbeitszeit[0] != null) {
              aktTag.arbeitsZeit = tempTag.arbeitszeit[0];
            }
            aktTag.anzeigeZeitTypAktiv = tempTag.anzeigeZeitTypAktiv;
            aktTag.anzeigeZeitTyp = tempTag.anzeigeZeitTyp;
            for (let d = 0; d < tempTag.abwesenheit.length; d++) {
              aktTag.abweseneheitszeiten.push(tempTag.abwesenheit[d]);

              for (let f = 0; f < tempTag.abwesenheit.length; f++) {
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.KRANK) aktFiliale.hasKrankheit = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.URLAUB) aktFiliale.hasUrlaub = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.FEIERTAG) aktFiliale.hasFeiertag = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.SCHULE) aktFiliale.hasSchule = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.SONSTIGES) aktFiliale.hasSonstiges = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.WOCHENENDE) aktFiliale.hasWochenende = true;
                if (tempTag.abwesenheit[f].abwesendTyp === APIEnumZeitTyp.RUHETAG) aktFiliale.hasRuhetag = true;
              }
            }
            // * Summeneintraege auf dem MA generieren
            for (let MAc = 0; MAc < getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitMATage.length; MAc++) {
              if (getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitMATage[MAc].datum.includes(tempTag.datum)) {
                let aktAnzeigeTyp = getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitMATage[MAc].anzeigeZeitTyp;
                let aktAnzeigeSummen = getSollArbeitszeitenFuerMitarbeiter[a].sollAZeitMATage[MAc].sollAZeitSumme;
                let aktAnzeigeSumme = "";
                if (aktAnzeigeTyp.includes(APIEnumZeitTyp.ARBEITSZEIT)) {
                  aktAnzeigeSumme = aktAnzeigeSummen.arbeitszeit;
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.SCHULE)) {
                  aktAnzeigeSumme = "SCH";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.SONSTIGES)) {
                  aktAnzeigeSumme = "SONST";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.URLAUB)) {
                  aktAnzeigeSumme = "U";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.KRANK)) {
                  aktAnzeigeSumme = "K";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.FEIERTAG)) {
                  aktAnzeigeSumme = "F";
                } else if (aktAnzeigeTyp.includes(APIEnumZeitTyp.WOCHENENDE)) {
                  aktAnzeigeSumme = "W";
                }
                aktTag.sumTag = aktAnzeigeSumme;
              }
            }
            aktFiliale.tage.push(aktTag);
          }

          if (convertToKalenderType.mitarbeiter === undefined) {
            aktMA.filialen.push(aktFiliale);
            boolIsPushed = true;
          } else {
            for (let aSoll = 0; aSoll < convertToKalenderType.mitarbeiter.length; aSoll++) {
              if (convertToKalenderType.mitarbeiter[aSoll].mitarbeiterId === mitarbeiterGot.maid) {
                convertToKalenderType.mitarbeiter[aSoll].filialen.push(aktFiliale);
                boolIsPushed = true;
              } else if (!boolIsPushed) {
                aktMA.filialen.push(aktFiliale);
                boolIsPushed = true;
              }
            }
          }
        }
        let boolIsPresent = false;
        if (convertToKalenderType.mitarbeiter === undefined) {
          convertToKalenderType.mitarbeiter = [];
        } else {
          for (let aSoll = 0; aSoll < convertToKalenderType.mitarbeiter.length; aSoll++) {
            if (convertToKalenderType.mitarbeiter[aSoll].mitarbeiterId == mitarbeiterGot.maid) {
              boolIsPresent = true;
            }
          }
        }
        if (!boolIsPresent) convertToKalenderType.mitarbeiter.push(aktMA);

        setNeuerMAFA(neuerMAFA + 1);
      }
    }
  };

  /**
   * Mitarbeiter und Filialen fuer Kalender erzeugen
   */
  const arrMitarbeiterFilialen = useMemo(() => {
    let arrReturn: {}[] = [];
    if (convertToKalenderType.mitarbeiter !== undefined && convertToKalenderType.mitarbeiter.length >= 1) {
      for (let i = 0; i < convertToKalenderType.mitarbeiter.length; i++) {
        let arrFilialen: {}[] = [];
        for (let y = 0; y < convertToKalenderType.mitarbeiter[i].filialen.length; y++) {
          arrFilialen.push({
            id:
              convertToKalenderType.mitarbeiter[i].mitarbeiterId +
              "" +
              convertToKalenderType.mitarbeiter[i].filialen[y].filialtoken,
            title: convertToKalenderType.mitarbeiter[i].filialen[y].filialname,
            sumA: convertToKalenderType.mitarbeiter[i].filialen[y].sumArbeitszeit,
            sumK: convertToKalenderType.mitarbeiter[i].filialen[y].sumKrankheit,
            sumU: convertToKalenderType.mitarbeiter[i].filialen[y].sumUrlaub,
          });
        }
        let returnArrMA = {
          id: convertToKalenderType.mitarbeiter[i].mitarbeiterName + convertToKalenderType.mitarbeiter[i].mitarbeiterId,
          title: convertToKalenderType.mitarbeiter[i].mitarbeiterName,
          children: arrFilialen,
          sumA: convertToKalenderType.mitarbeiter[i].sumArbeitszeit,
          sumK: convertToKalenderType.mitarbeiter[i].sumKrankheit,
          sumU: convertToKalenderType.mitarbeiter[i].sumUrlaub,
        };
        arrReturn.push(returnArrMA);
      }
    }

    return arrReturn;
  }, [convertToKalenderType, neuerMAFA]);

  /**
   * Erzeugen von Leerevents für Abwesenheiten
   */
  const generateEmptyEventAbwesenheit = (
    aktMA: sollArbeitszeitenMitarbeiter,
    aktFiliale: sollArbeitszeitenMitarbeiterFilialen,
    aktTag: sollArbeitszeitenTag,
    type: APIEnumZeitTyp
  ) => {
    let emptyEventObject: {} = {};
    Object.assign(emptyEventObject, { resourceId: aktMA.mitarbeiterId + "" + aktFiliale.filialtoken });
    Object.assign(emptyEventObject, { title: "+" });
    Object.assign(emptyEventObject, { start: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag });
    Object.assign(emptyEventObject, {
      aktTagDetail: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
    });
    Object.assign(emptyEventObject, { end: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag });
    Object.assign(emptyEventObject, { color: colorLeerEvent });
    Object.assign(emptyEventObject, { textColor: colorLeerEvent });
    Object.assign(emptyEventObject, { allDay: true });
    Object.assign(emptyEventObject, { detailTyp: "Abwesenheit" });

    Object.assign(emptyEventObject, { detailMA: "" + aktMA.mitarbeiterName });
    Object.assign(emptyEventObject, { detailMAID: "" + aktMA.mitarbeiterId });
    Object.assign(emptyEventObject, { detailFiliale: "" + aktFiliale.filialname });

    Object.assign(emptyEventObject, {
      detailDatumVon:
        "" +
        dayjs(aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag).format("YYYY-MM-DDTHH:MM:ss.SSS") +
        "Z",
    });
    Object.assign(emptyEventObject, {
      detailDatumBis:
        "" +
        dayjs(aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag).format("YYYY-MM-DDTHH:MM:ss.SSS") +
        "Z",
    });
    Object.assign(emptyEventObject, { detailUhrzeitVon: "00:00" });
    Object.assign(emptyEventObject, { detailUhrzeitBis: "00:00" });
    Object.assign(emptyEventObject, { detailBemerkung: "" });
    Object.assign(emptyEventObject, { detailHalberTag: false });
    Object.assign(emptyEventObject, { detailTurnusTyp: APIEnumTurnusTyp.TAEGLICH });
    Object.assign(emptyEventObject, { evenData: {} });
    if (type === APIEnumZeitTyp.KRANK) {
      Object.assign(emptyEventObject, { order: "1" });
      Object.assign(emptyEventObject, { detailAbwesenheitsTyp: APIEnumZeitTyp.KRANK });
    } else if (type === APIEnumZeitTyp.WOCHENENDE) {
      Object.assign(emptyEventObject, { order: "2" });
      Object.assign(emptyEventObject, { detailAbwesenheitsTyp: APIEnumZeitTyp.WOCHENENDE });
    } else if (type === APIEnumZeitTyp.URLAUB) {
      Object.assign(emptyEventObject, { order: "3" });
      Object.assign(emptyEventObject, { detailAbwesenheitsTyp: APIEnumZeitTyp.URLAUB });
    } else if (type === APIEnumZeitTyp.RUHETAG) {
      Object.assign(emptyEventObject, { order: "4" });
      Object.assign(emptyEventObject, { detailAbwesenheitsTyp: APIEnumZeitTyp.RUHETAG });
    } else if (type === APIEnumZeitTyp.FEIERTAG) {
      Object.assign(emptyEventObject, { order: "5" });
      Object.assign(emptyEventObject, { detailAbwesenheitsTyp: APIEnumZeitTyp.FEIERTAG });
    } else if (type === APIEnumZeitTyp.SCHULE) {
      Object.assign(emptyEventObject, { order: "6" });
      Object.assign(emptyEventObject, { detailAbwesenheitsTyp: APIEnumZeitTyp.SCHULE });
    } else if (type === APIEnumZeitTyp.SONSTIGES) {
      Object.assign(emptyEventObject, { order: "7" });
      Object.assign(emptyEventObject, { detailAbwesenheitsTyp: APIEnumZeitTyp.SONSTIGES });
    }
    return emptyEventObject;
  };

  /**
   * Die Events fuer den Kalender erzeugen
   */
  const arrEvents = useMemo(() => {
    let arrReturnEvents: {}[] = [];
    if (convertToKalenderType.mitarbeiter !== undefined && convertToKalenderType.mitarbeiter.length >= 1) {
      for (let a = 0; a < convertToKalenderType.mitarbeiter.length; a++) {
        let aktMA = convertToKalenderType.mitarbeiter[a];
        for (let b = 0; b < aktMA.filialen.length; b++) {
          let aktFiliale = aktMA.filialen[b];

          for (let c = 0; c < aktFiliale.tage.length; c++) {
            let aktTag = aktFiliale.tage[c];
            //Summenzeile des jeweiligen Mitarbeiters
            if (aktTag.sumTag != null && !aktTag.sumTag.includes("00:00") && b === 0) {
              let colorSummenfeld = colorSumme;
              if (aktTag.sumTag === "U") {
                colorSummenfeld = colorUrlaubstag;
              } else if (aktTag.sumTag === "K") {
                colorSummenfeld = colorKrankheitstag;
              } else if (aktTag.sumTag === "W") {
                colorSummenfeld = colorWochenende;
              } else if (aktTag.sumTag === "SCH") {
                colorSummenfeld = colorSumme;
              } else if (aktTag.sumTag === "SON") {
                colorSummenfeld = colorSumme;
              } else if (aktTag.sumTag.includes(":")) {
                colorSummenfeld = colorSumme;
              } else if (aktTag.sumTag === "F") {
                colorSummenfeld = colorFeiertag;
              }
              let aktSumTitle = aktTag.sumTag;
              if (
                aktTag.anzeigeZeitTypAktiv != null &&
                !aktTag.anzeigeZeitTypAktiv &&
                (aktTag.anzeigeZeitTyp === APIEnumZeitTyp.URLAUB || aktTag.anzeigeZeitTyp === APIEnumZeitTyp.KRANK)
              ) {
                aktSumTitle = "(" + aktSumTitle + ")";
              }
              arrReturnEvents.push({
                resourceId: aktMA.mitarbeiterName + aktMA.mitarbeiterId,
                title: aktSumTitle,
                start: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                aktTagDetail: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                end: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                color: colorSummenfeld,
                allDay: true,
                order: "0",
                detailTyp: "Summe",
                detailMA: "" + getMANameFromID(aktMA.mitarbeiterId, stammdaten.stammdaten.mitarbeiterJeFiliale),
                detailMAID: "" + aktMA.mitarbeiterId,
                detailFiliale: "",
                evenData: aktTag.arbeitsZeit,
              });
            } else if (aktTag.sumTag != null && aktTag.sumTag.includes("00:00") && b === 0) {
              arrReturnEvents.push({
                resourceId: aktMA.mitarbeiterName + aktMA.mitarbeiterId,
                title: "",
                start: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                aktTagDetail: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                end: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                color: colorSumme,
                allDay: true,
                order: "0",
                detailTyp: "Summe",
                detailMA: "" + getMANameFromID(aktMA.mitarbeiterId, stammdaten.stammdaten.mitarbeiterJeFiliale),
                detailMAID: "" + aktMA.mitarbeiterId,
                detailFiliale: "",
                evenData: aktTag.arbeitsZeit,
              });
            }
            if (aktTag.arbeitsZeit != null) {
              let aktTurnus = APIEnumTurnusTyp.JEDEWOCHE;
              if (
                aktTag.arbeitsZeit.turnusTyp === APIEnumTurnusTyp.ZWEIWOECHENTLICH ||
                aktTag.arbeitsZeit.turnusTyp === APIEnumTurnusTyp.GERADEWOCHE ||
                aktTag.arbeitsZeit.turnusTyp === APIEnumTurnusTyp.UNGERADEWOCHE
              ) {
                aktTurnus = APIEnumTurnusTyp.ZWEIWOECHENTLICH;
              }
              let aktTitle = aktTag.arbeitsZeit.arbeitszeit + "";
              if (aktTag.anzeigeZeitTyp === APIEnumZeitTyp.ARBEITSZEIT && aktTag.anzeigeZeitTypAktiv) {
                aktTitle = "(" + aktTitle + ")";
              }

              arrReturnEvents.push({
                resourceId: aktMA.mitarbeiterId + "" + aktFiliale.filialtoken,
                title: aktTitle,
                start: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                aktTagDetail: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                end: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                color: colorArbeitstag,
                allDay: true,
                order: "0",
                detailTyp: "Arbeitszeit",
                detailMA: "" + aktMA.mitarbeiterName,
                detailMAID: "" + aktMA.mitarbeiterId,
                detailFiliale: "" + aktFiliale.filialname,
                detailAbwesenheitsTyp: aktTag.arbeitsZeit.arbeitszeit + " Stunden",
                detailDatumVon: aktTag.arbeitsZeit.datumVon,
                detailDatumBis: aktTag.arbeitsZeit.datumBis,
                detailBemerkung: aktTag.arbeitsZeit.bemerkung,
                detailUhrzeitVon: aktTag.arbeitsZeit.uhrzeitVon,
                detailUhrzeitBis: aktTag.arbeitsZeit.uhrzeitBis,
                detailPausefix: aktTag.arbeitsZeit.pauseFix,
                detailPause: aktTag.arbeitsZeit.pause,
                detailArbeitszeit: aktTag.arbeitsZeit.arbeitszeit,
                detailPause1Von: aktTag.arbeitsZeit.pause1Von,
                detailPause2Von: aktTag.arbeitsZeit.pause2Von,
                detailPause1Bis: aktTag.arbeitsZeit.pause1Bis,
                detailPause2Bis: aktTag.arbeitsZeit.pause2Bis,
                detailTurnusTyp: aktTurnus,
                evenData: aktTag.arbeitsZeit,
              });
            } else if (aktTag.arbeitsZeit == null) {
              arrReturnEvents.push({
                resourceId: aktMA.mitarbeiterId + "" + aktFiliale.filialtoken,
                title: "+",
                start: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                aktTagDetail: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                end: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                color: colorLeerEvent,
                allDay: true,
                order: "0",
                detailTyp: "Arbeitszeit",
                detailMA: "" + aktMA.mitarbeiterName,
                detailMAID: "" + aktMA.mitarbeiterId,
                detailFiliale: "" + aktFiliale.filialname,
                detailAbwesenheitsTyp: "",
                detailDatumVon:
                  "" +
                  dayjs(aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag).format(
                    "YYYY-MM-DDTHH:MM:ss.SSS"
                  ) +
                  "Z",
                detailDatumBis:
                  "" +
                  dayjs(aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag).format(
                    "YYYY-MM-DDTHH:MM:ss.SSS"
                  ) +
                  "Z",
                detailBemerkung: "",
                detailUhrzeitVon: "08:00",
                detailUhrzeitBis: "19:00",
                detailPausefix: "false",
                detailPause: "00:00",
                detailPause1Von: "00:00",
                detailPause2Von: "00:00",
                detailPause1Bis: "00:00",
                detailPause2Bis: "00:00",
                detailTurnusTyp: "" + APIEnumTurnusTyp.JEDEWOCHE,
                evenData: {},
              });
            }

            let tempHasKrank = false;
            let tempHasWochenende = false;
            let tempHasUrlaub = false;
            let tempHasRuhetag = false;
            let tempHasFeiertag = false;
            let tempHasSchule = false;
            let tempHasSonstiges = false;

            let aktTurnus = APIEnumTurnusTyp.JEDEWOCHE;
            if (aktTag.abweseneheitszeiten.length > 0) {
              for (let d = 0; d < aktTag.abweseneheitszeiten.length; d++) {
                let aktAbwesenheit: APISollAZeitFilialTagAbwesenheit = aktTag.abweseneheitszeiten[d];
                let aktAnzeige = "?";
                let aktOrder = "999;";
                let aktColor = colorLeerEvent;

                if (
                  aktAbwesenheit.turnusTyp === APIEnumTurnusTyp.ZWEIWOECHENTLICH ||
                  aktAbwesenheit.turnusTyp === APIEnumTurnusTyp.GERADEWOCHE ||
                  aktAbwesenheit.turnusTyp === APIEnumTurnusTyp.UNGERADEWOCHE
                ) {
                  aktTurnus = APIEnumTurnusTyp.ZWEIWOECHENTLICH;
                } else {
                  aktTurnus = aktAbwesenheit.turnusTyp;
                }
                if (aktAbwesenheit.abwesendTyp === APIEnumZeitTyp.KRANK) {
                  if (aktTag.anzeigeZeitTyp === APIEnumZeitTyp.KRANK && !aktTag.anzeigeZeitTypAktiv) aktAnzeige = "(K)";
                  else aktAnzeige = "K";
                  aktColor = colorKrankheitstag;
                  tempHasKrank = true;
                  aktOrder = "1";
                } else if (aktAbwesenheit.abwesendTyp === APIEnumZeitTyp.WOCHENENDE) {
                  aktAnzeige = "W";
                  aktColor = colorWochenende;
                  tempHasWochenende = true;
                  aktOrder = "2";
                } else if (aktAbwesenheit.abwesendTyp === APIEnumZeitTyp.URLAUB) {
                  if (aktTag.anzeigeZeitTyp === APIEnumZeitTyp.URLAUB && !aktTag.anzeigeZeitTypAktiv)
                    aktAnzeige = "(U)";
                  else aktAnzeige = "U";
                  aktColor = colorUrlaubstag;
                  tempHasUrlaub = true;
                  aktOrder = "3";
                } else if (aktAbwesenheit.abwesendTyp === APIEnumZeitTyp.RUHETAG) {
                  aktAnzeige = "R";
                  aktColor = colorRuhetag;
                  tempHasRuhetag = true;
                  aktOrder = "4";
                } else if (aktAbwesenheit.abwesendTyp === APIEnumZeitTyp.FEIERTAG) {
                  aktAnzeige = "F";
                  aktColor = colorFeiertag;
                  tempHasFeiertag = true;
                  aktOrder = "5";
                } else if (aktAbwesenheit.abwesendTyp === APIEnumZeitTyp.SCHULE) {
                  aktAnzeige = "SCH";
                  aktColor = colorSchule;
                  tempHasSchule = true;
                  aktOrder = "6";
                } else if (aktAbwesenheit.abwesendTyp === APIEnumZeitTyp.SONSTIGES) {
                  aktAnzeige = "SONST";
                  aktColor = colorSonstiges;
                  tempHasSonstiges = true;
                  aktOrder = "7";
                }
                arrReturnEvents.push({
                  resourceId: aktMA.mitarbeiterId + "" + aktFiliale.filialtoken,
                  title: aktAnzeige,
                  start: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                  aktTagDetail: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                  end: aktTag.dataJahr + "-" + aktTag.dataMonat + "-" + aktTag.dataTag,
                  color: aktColor,
                  allDay: true,
                  order: aktOrder,
                  detailTyp: "Abwesenheit",
                  detailMA: "" + aktMA.mitarbeiterName,
                  detailMAID: "" + aktMA.mitarbeiterId,
                  detailFiliale: "" + aktFiliale.filialname,
                  detailAbwesenheitsTyp: aktAbwesenheit.abwesendTyp,
                  detailDatumVon: aktAbwesenheit.datumVon,
                  detailDatumBis: aktAbwesenheit.datumBis,
                  detailUhrzeitVon: aktAbwesenheit.uhrzeitVon,
                  detailUhrzeitBis: aktAbwesenheit.uhrzeitBis,
                  detailBemerkung: aktAbwesenheit.bemerkung,
                  detailHalberTag: aktAbwesenheit.halberTag,
                  detailTurnusTyp: aktTurnus,
                  evenData: aktAbwesenheit,
                });
              }
            }

            if (!tempHasKrank && aktFiliale.hasKrankheit) {
              arrReturnEvents.push(generateEmptyEventAbwesenheit(aktMA, aktFiliale, aktTag, APIEnumZeitTyp.KRANK));
            }
            if (!tempHasWochenende && aktFiliale.hasWochenende) {
              arrReturnEvents.push(generateEmptyEventAbwesenheit(aktMA, aktFiliale, aktTag, APIEnumZeitTyp.WOCHENENDE));
            }
            if (!tempHasUrlaub && aktFiliale.hasUrlaub) {
              arrReturnEvents.push(generateEmptyEventAbwesenheit(aktMA, aktFiliale, aktTag, APIEnumZeitTyp.URLAUB));
            }
            if (!tempHasRuhetag && aktFiliale.hasRuhetag) {
              arrReturnEvents.push(generateEmptyEventAbwesenheit(aktMA, aktFiliale, aktTag, APIEnumZeitTyp.RUHETAG));
            }
            if (!tempHasSchule && aktFiliale.hasSchule) {
              arrReturnEvents.push(generateEmptyEventAbwesenheit(aktMA, aktFiliale, aktTag, APIEnumZeitTyp.SCHULE));
            }
            if (!tempHasFeiertag && aktFiliale.hasFeiertag) {
              arrReturnEvents.push(generateEmptyEventAbwesenheit(aktMA, aktFiliale, aktTag, APIEnumZeitTyp.FEIERTAG));
            }
            if (!tempHasSonstiges && aktFiliale.hasSonstiges) {
              arrReturnEvents.push(generateEmptyEventAbwesenheit(aktMA, aktFiliale, aktTag, APIEnumZeitTyp.SONSTIGES));
            }
          }
        }
      }
    }

    isLoading = false;

    return arrReturnEvents;
  }, [convertToKalenderType, neuerMAFA]);

  /**
   * Von Monat auf Woche gewechselt
   */
  const switchCalendarToWeek = () => {
    //TODO chekcen ob der erste ein Sonntag ist und in dem fall -7 TAge
    let startDatum: dayjs.Dayjs = dayjs(startDate).startOf("week");

    if (startDatum.day() === 0 && startDatum.format("DD") === "01") {
      startDatum = startDatum.subtract(6, "days");
    } else {
      startDatum = startDatum.add(1, "days");
    }

    setStartDate(startDatum.format("YYYY-MM-DD") + "T00:00:00.000Z");
    setEndDate(dayjs(startDate).day(7).format("YYYY-MM-DD") + "T00:00:00.000Z");
    let start: string = startDatum.format("YYYY-MM-DD") + "T00:00:00.000Z";
    let end: string = dayjs(startDate).day(7).format("YYYY-MM-DD") + "T00:00:00.000Z";
    setDateData({
      startDateData: start,
      endDateData: end,
    });

    calendarRef.current?.getApi().changeView("customWeek", "" + startDate);
    setAktCalendarView("Wochenansicht");
  };

  /**
   * von Woche auf Monat gewechselt
   */
  const switchCalendarToMonth = () => {
    setStartDate(dayjs(dateData.endDateData).startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z");
    setEndDate(dayjs(dateData.endDateData).endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z");
    setDateData({
      startDateData: dayjs(dateData.startDateData).startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
      endDateData: dayjs(dateData.startDateData).endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
    });
    calendarRef.current?.getApi().changeView("customMonth", "" + startDate);

    setAktCalendarView("Monatsansicht");
  };

  /**
   * Datepickeraenderung
   * @param startMoment
   */
  const handleChangeDate = (startMoment: Moment) => {
    if (aktCalendarView.includes("Monatsansicht")) {
      setStartDate(startMoment.startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z");
      setEndDate(startMoment.endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z");
      setDateData({
        startDateData: startMoment.startOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
        endDateData: startMoment.endOf("month").format("YYYY-MM-DD") + "T00:00:00.000Z",
      });
      calendarRef.current?.getApi().changeView("customMonth", "" + startMoment.format("YYYY-MM-DD"));
    }
    if (aktCalendarView.includes("Wochenansicht")) {
      setStartDate(startMoment.format("YYYY-MM-DD") + "T00:00:00.000Z");
      setEndDate(startMoment.endOf("week").add(1, "day").format("YYYY-MM-DD") + "T00:00:00.000Z");
      setDateData({
        startDateData: startMoment.startOf("week").add(1, "day").format("YYYY-MM-DD") + "T00:00:00.000Z",
        endDateData: startMoment.endOf("week").add(1, "day").format("YYYY-MM-DD") + "T00:00:00.000Z",
      });
      calendarRef.current?.getApi().changeView("customWeek", "" + startMoment.format("YYYY-MM-DD"));
    }
  };

  /**
   * Detailmodal fuer Arbeitszeit oder Abwesenheit öffnen
   * @param event
   */
  const openDetail = (event: EventImpl) => {
    if (!displayModalMenu) {
      let aktDetailData: Dictionary = event.extendedProps;
      setDetailData(aktDetailData);
      let eventTitle = event.title;

      if (
        aktDetailData.detailTyp.includes("Abwesenheit") &&
        (aktDetailData.detailAbwesenheitsTyp === APIEnumZeitTyp.RUHETAG ||
          aktDetailData.detailAbwesenheitsTyp === APIEnumZeitTyp.FEIERTAG ||
          aktDetailData.detailAbwesenheitsTyp === APIEnumZeitTyp.WOCHENENDE) &&
        !isCompanyAdministrator()
      ) {
        let errorContent = {} as typeModalBestaetigung;
        errorContent.titel = "Fehlende Berechtigungen";
        errorContent.text =
          "Du hast keine Berechtigung, um " +
          aktDetailData.detailAbwesenheitsTyp +
          " anzulegen, zu ändern oder zu löschen.";
        errorContent.okButton = "Schließen";
        setDisplayModalErrorContent(errorContent);
        setDisplayModalError(true);
      } else {
        globalMemory.pause1Von = dayjs("2000-01-01" + aktDetailData.detailPause1Von);
        globalMemory.pause1Bis = dayjs("2000-01-01" + aktDetailData.detailPause1Bis);
        globalMemory.pause2Von = dayjs("2000-01-01" + aktDetailData.detailPause2Von);
        globalMemory.pause2Bis = dayjs("2000-01-01" + aktDetailData.detailPause2Bis);

        if (eventTitle.includes("+")) setDisplayNewEvent(true);
        else setDisplayNewEvent(false);

        if (aktDetailData.detailTyp.includes("Abwesenheit") && !event.extendedProps.detailTyp.includes("Summe")) {
          setDisplayFirstOpen(true);
          if (
            aktDetailData.detailAbwesenheitsTyp === APIEnumZeitTyp.URLAUB ||
            aktDetailData.detailAbwesenheitsTyp === APIEnumZeitTyp.KRANK
          )
            setisUrlaubOderKrank(true);
          else setisUrlaubOderKrank(false);

          setDisplaySettingsModalAbwesenheit(true);
          if (Object.keys(aktDetailData.evenData).length !== 0) {
            setDisplayAbwesenheitSelectDisabled(true);
          } else {
            setDisplayAbwesenheitSelectDisabled(false);
          }
          //wenn bisDatum 2099 ist dann setze "Unbegrenzt Gueltig" fuer Abwesenheiten
          if (("" + aktDetailData.detailDatumBis).includes("2099")) {
            setDisplayDetailBisEndlosAbwesenheit(true);
          } else {
            setDisplayDetailBisEndlosAbwesenheit(false);
          }
          if (aktDetailData.detailAbwesenheitsTyp.includes("" + APIEnumZeitTyp.SCHULE)) {
            setDisplayAbwesenheitSelectSchule(true);
          } else {
            setDisplayAbwesenheitSelectSchule(false);
          }
        } else if (
          aktDetailData.detailTyp.includes("Arbeitszeit") &&
          !event.extendedProps.detailTyp.includes("Summe")
        ) {
          setDisplayDetailPause(aktDetailData.detailPausefix);
          setDisplayFirstOpen(true);

          //wenn bisDatum 2099 ist dann setze "Unbegrenzt Gueltig" fuer Arbeitszeiten
          if (("" + aktDetailData.detailDatumBis).includes("2099")) {
            setDisplayDetailBisEndlosArbeitszeit(true);
          } else {
            setDisplayDetailBisEndlosArbeitszeit(false);
          }
          setDisplaySettingsModalArbeitszeit(true);
        }
      }
    }
  };

  const handleOkSelectMAFA = (values: any) => {
    setDisplayModalSelectMAFA(false);
    let valueSend: string[] = [] as string[];
    if (isVerbund) {
      valueSend.push("" + values.FAID);
    } else {
      valueSend.push(aktFilialToken);
    }
    getSollArbeitszeitenFuerMitarbeiter(valueSend as [], values.MAID.split("_+_")[1], "addMA");
  };

  const handleCancelSelectMAFA = () => {
    setDisplayModalSelectMAFA(false);
  };

  /**
   * Das Submit des Forms zum aendern der Arbeitszeiten oder Abwesenheitszeiten
   * @param values
   */
  const handleOkModalBestaetigung = (values: any, isDelete: boolean) => {
    let datumTag = "" + dayjs(detailData.aktTagDetail).format("DD.MM.YYYY");
    if (isDelete) {
      setDisplayModalBestaetigungIsDelete(true);
    } else {
      setDisplayModalBestaetigungIsDelete(false);
    }
    let aktBestaetitungsModal = {} as typeModalBestaetigung;
    if (displayDetailEditZeitKontext) aktBestaetitungsModal.titel = "Serie";
    else aktBestaetitungsModal.titel = "Tag";

    if (isDelete) aktBestaetitungsModal.titel += " löschen";
    else aktBestaetitungsModal.titel += " bearbeiten";

    aktBestaetitungsModal.datum = datumTag;
    aktBestaetitungsModal.ma = detailData.detailMA;

    if (isDelete) {
      if (!displayDetailEditZeitKontext) aktBestaetitungsModal.text = "Den Eintrag für diesen Tag wirklich löschen? ";
      else aktBestaetitungsModal.text = "Diesen Serieneintrag wirklich löschen? ";
      aktBestaetitungsModal.okButton = "Löschen";
      setIsBackDelete(true);
    } else {
      setIsBackDelete(false);
      if (detailData.detailTyp === "Arbeitszeit")
        aktBestaetitungsModal.text = "Den geänderten Arbeitszeiteintrag speichern?";
      else if (detailData.detailTyp === "Abwesenheit")
        aktBestaetitungsModal.text = "Den geänderten Abwesenheitseintrag speichern?";
      aktBestaetitungsModal.okButton = "Speichern";
    }

    setDisplayModalBestaetigungContent(aktBestaetitungsModal);

    if (detailData.detailTyp === "Arbeitszeit") {
      globalMemory.undoCache.push({
        index: globalMemory.undoCache.length + 1,
        abwesenheit: {} as APISollAZeitFilialTagAbwesenheit,
        arbeitszeit: detailData.evenData,
        datum: dayjs(detailData.aktTagDetail).format("DD.MM.YYYY"),
        maID: detailData.detailMAID,
        type: "EDIT",
      });
    } else if (detailData.detailTyp === "Abwesenheit") {
      globalMemory.undoCache.push({
        index: globalMemory.undoCache.length + 1,
        abwesenheit: detailData.evenData,
        arbeitszeit: {} as APISollAZeitFilialTagArbeitszeit,
        datum: dayjs(detailData.aktTagDetail).format("DD.MM.YYYY"),
        maID: detailData.detailMAID,
        type: "EDIT",
      });
    }

    if (isDelete) setOKButtonColor("orangered");
    else setOKButtonColor("#4096ff");

    setDisplayModalBestaetigungValues(values);
    if (detailData.detailTyp === "Arbeitszeit") {
      setDisplaySettingsModalArbeitszeit(false);
    } else if (detailData.detailTyp === "Abwesenheit") {
      setDisplaySettingsModalAbwesenheit(false);
    }

    setDisplayModalBestaetigung(true);
  };

  /**
   *
   * @param value
   */
  const handleChangeAbwesenheitTyp = (value: string) => {
    if (value.includes(APIEnumZeitTyp.SCHULE)) {
      setDisplayAbwesenheitSelectSchule(true);
    } else setDisplayAbwesenheitSelectSchule(false);
    setDisplayAbwesenheitSelect(value);
  };

  const handleOkDelete = (values: any) => {
    handleOkModalBestaetigung(values, true);
  };

  /**
   * Prüfen ob der gesendete Query Fehlerzurückmeldet und diese dann in einem Modal anzeigen
   */
  const checkQueryRespone = (data: APIErfolgRueckmeldung) => {
    let errorContent = {} as typeModalBestaetigung;
    if (data === null) {
      console.log("error");
      errorContent.titel = "Kritischer Fehler";
      errorContent.text = "Datenobjet ist leer.  Bitte melde dich beim Support!";
      errorContent.okButton = "Schließen";
      setDisplayModalErrorContent(errorContent);
      setDisplayModalError(true);
    } else if (!data.erfolgreich) {
      console.log("errorfenster");
      errorContent.titel = data.ueberschrift;
      errorContent.text = data.infotext;
      errorContent.okButton = "Schließen";
      setDisplayModalErrorContent(errorContent);
      setDisplayModalError(true);
    } else if (data.erfolgreich) {
      console.log("Request:" + data.erfolgreich);
      reloadAndResetAfterModal();
    } else {
      console.log("Fehler :" + data.infotext);
      errorContent.titel = "Kritischer Fehler";
      errorContent.text = "Hier sollten wir nie landen. Bitte melde dich beim Support!";
      errorContent.okButton = "Schließen";
      setDisplayModalErrorContent(errorContent);
      setDisplayModalError(true);
    }
  };

  /**
   *  Delete zur APi schicken
   */
  const handleOkDeleteBestaetigt = () => {
    setDisplayModalBestaetigung(false);
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");
    let objectDelete: typeDeleteZeitraum = {} as typeDeleteZeitraum;
    objectDelete.id = detailData.evenData.id;

    if (displayDetailEditZeitKontext) objectDelete.zeitKontext = APIEnumZeitKontext.SERIE;
    else objectDelete.zeitKontext = APIEnumZeitKontext.EINZEL;
    objectDelete.datum = "" + dayjs(detailData.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    if (detailData.detailAbwesenheitsTyp.includes("Stunden")) {
      fetchData(getQueryDeleteZeitraumArbeitszeit({ userToken, filialToken, objectDelete })).then(function (response) {
        // TODO: Response ausgeben ob erfolgreich oder Fehler
        let temp = response.data?.data?.deleteSollArbeitszeit;
        checkQueryRespone(temp.data);
      });
    } else {
      fetchData(getQueryDeleteZeitraumAbwesenheit({ userToken, filialToken, objectDelete })).then(function (response) {
        // TODO: Response ausgeben ob erfolgreich oder Fehler
        let temp = response.data?.data?.deleteAbwesenheitszeit;
        checkQueryRespone(temp.data);
      });
    }
  };
  /**
   *   Kopieren zur APi schicken
   */
  const handleOkKopierenBestaetigt = () => {
    setDisplayModalBestaetigungKopieren(false);
    sendZeitraumKopieren(globalMemory.kopieTyp);
  };
  /**
   * Geaenderte Daten zur API schicken nachdem Modal bestaetigt wurde
   */
  const handleOKBestaetigungsModal = () => {
    if (displayModalBestaetigungIsDelete) {
      setDisplayModalBestaetigungIsDelete(false);
      handleOkDeleteBestaetigt();
    } else if (detailData.detailTyp === "Arbeitszeit") {
      sendArbeitszeitDataToAPI();
    } else if (detailData.detailTyp === "Abwesenheit") {
      sendAbwesenheitDataToAPI();
    }
  };

  /**
   * Nach Fetch Kalender neu laden und detail leeren
   */
  const reloadAndResetAfterModal = () => {
    reloadData();
    setDisplayModalBestaetigungValues({});
    setDetailData(initEvent);
    hideAll();
  };

  /**
   * Abwesenheit senden
   */
  const sendAbwesenheitDataToAPI = () => {
    let values: any = displayModalBestaetigungValues;
    setDisplayModalBestaetigung(false);
    let objectSetAbwesenheit: typeSetAbwesenheit = {} as typeSetAbwesenheit;
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");
    objectSetAbwesenheit.bemerkung = values.bemerkung;
    if (Object.keys(detailData.evenData).length !== 0) {
      objectSetAbwesenheit.dbid = detailData.evenData.id;
    } else {
      objectSetAbwesenheit.dbid = 0;
    }

    if (displayDetailEditZeitKontext) {
      objectSetAbwesenheit.zeitKontext = APIEnumZeitKontext.SERIE;
    } else {
      objectSetAbwesenheit.zeitKontext = APIEnumZeitKontext.EINZEL;
    }

    objectSetAbwesenheit.maid = detailData.detailMAID;
    objectSetAbwesenheit.abwesendTyp = values.detailAbwesenheitsTyp;
    objectSetAbwesenheit.datum = "" + dayjs(detailData.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    objectSetAbwesenheit.datumVon = "" + dayjs(values.gueltigAb).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    if (displayDetailBisEndlosAbwesenheit) {
      objectSetAbwesenheit.datumBis = "" + dayjs("2099-12-31").format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z"; //.day(31).month(11).year(2099)
    } else {
      objectSetAbwesenheit.datumBis = "" + dayjs(values.gueltigBis).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    }
    objectSetAbwesenheit.turnusTyp = values.turnus;
    objectSetAbwesenheit.uhrzeitVon = dayjs(values.ArbeitszeitAb).second(0).format("HH:mm");
    objectSetAbwesenheit.uhrzeitBis = dayjs(values.ArbeitszeitBis).second(0).format("HH:mm");
    objectSetAbwesenheit.halberTag = false;

    fetchData(getQuerySetAbwesenheit({ userToken, filialToken, objectSetAbwesenheit })).then(function (response) {
      // TODO: Response ausgeben ob erfolgreich oder Fehler
      let temp = response.data?.data?.setAbwesenheitszeit;
      checkQueryRespone(temp.data);
    });
  };

  /**
   * Abwesenheit abbrechen und wieder zurück gehen zum Eingabefenster
   */
  const abbrechenAbwesenheit = () => {
    let values: any = displayModalBestaetigungValues;

    let aktDetailData: Dictionary = {};

    setDisplayModalBestaetigung(false);
    if (!isBackDelete) {
      aktDetailData.detailBemerkung = values.bemerkung;
      aktDetailData.detailAbwesenheitsTyp = values.detailAbwesenheitsTyp;
      aktDetailData.detailDatumVon = "" + dayjs(values.gueltigAb).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
      if (values.unbegrenzt) {
        aktDetailData.detailDatumBis = "" + dayjs("2099-12-31").format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z"; //.day(31).month(11).year(2099)
      } else {
        aktDetailData.detailDatumBis = "" + dayjs(values.gueltigBis).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
      }
      aktDetailData.detailTurnusTyp = values.turnus;
      aktDetailData.detailUhrzeitVon = dayjs(values.ArbeitszeitAb).second(0).format("HH:mm");
      aktDetailData.detailUhrzeitBis = dayjs(values.ArbeitszeitBis).second(0).format("HH:mm");
      aktDetailData.aktTagDetail = detailData.aktTagDetail;
      aktDetailData.detailFiliale = detailData.detailFiliale;
      aktDetailData.detailMA = detailData.detailMA;
      aktDetailData.detailMAID = detailData.detailMAID;
      aktDetailData.detailTyp = detailData.detailTyp;
      aktDetailData.evendata = detailData.evenData;
      aktDetailData.order = detailData.order;

      if (detailData.detailTyp.includes("Arbeitszeit")) {
        aktDetailData.detailArbeitszeit = detailData.detailArbeitszeit;

        aktDetailData.detailPause = values.pause.format("HH:mm");

        if (values.pause1Bis !== undefined) {
          aktDetailData.detailPause1Bis = values.pause1Bis.format("HH:mm");
        } else {
          aktDetailData.detailPause1Bis = "00:00";
        }

        if (values.pause1Von !== undefined) {
          aktDetailData.detailPause1Von = values.pause1Von.format("HH:mm");
        } else {
          aktDetailData.detailPause1Von = "00:00";
        }
        if (values.pause2Bis !== undefined) {
          aktDetailData.detailPause2Bis = values.pause2Bis.format("HH:mm");
        } else {
          aktDetailData.detailPause2Bis = "00:00";
        }
        if (values.pause2Von !== undefined) {
          aktDetailData.detailPause2Von = values.pause2Von.format("HH:mm");
        } else {
          aktDetailData.detailPause2Von = "00:00";
        }

        globalMemory.pause1Von = dayjs("2000-01-01" + aktDetailData.detailPause1Von);
        globalMemory.pause1Bis = dayjs("2000-01-01" + aktDetailData.detailPause1Bis);
        globalMemory.pause2Von = dayjs("2000-01-01" + aktDetailData.detailPause2Von);
        globalMemory.pause2Bis = dayjs("2000-01-01" + aktDetailData.detailPause2Bis);

        aktDetailData.detailPausefix = values.PauseVariabel;

        setDetailData(aktDetailData);
      } else {
        aktDetailData.detailHalberTag = false;
        aktDetailData.aktTagDetail = detailData.aktTagDetail;

        setDetailData(aktDetailData);
      }
    }
    if (detailData.detailTyp.includes("Arbeitszeit")) {
      setDisplaySettingsModalArbeitszeit(true);
    } else {
      setDisplaySettingsModalAbwesenheit(true);
    }
  };

  /**
   * Arbeitszeit senden
   */
  const sendArbeitszeitDataToAPI = () => {
    let values: any = displayModalBestaetigungValues;

    setDisplayModalBestaetigung(false);
    let objectSetSollArbeitszeitenTag: typeSetSollArbeitszeitenTag = {} as typeSetSollArbeitszeitenTag;
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");
    objectSetSollArbeitszeitenTag.bemerkung = values.bemerkung;

    if (displayDetailEditZeitKontext) {
      objectSetSollArbeitszeitenTag.zeitKontext = APIEnumZeitKontext.SERIE;
    } else {
      objectSetSollArbeitszeitenTag.zeitKontext = APIEnumZeitKontext.EINZEL;
    }
    objectSetSollArbeitszeitenTag.turnusTyp = values.turnus;
    if (values.pause1Von !== undefined) {
      objectSetSollArbeitszeitenTag.pause1Von = dayjs(values.pause1Von).second(0).format("HH:mm");
    } else {
      if (detailData.evenData.pause1Von !== undefined) {
        objectSetSollArbeitszeitenTag.pause1Von = detailData.evenData.pause1Von;
      } else {
        objectSetSollArbeitszeitenTag.pause1Von = "00:00";
      }
    }

    if (values.pause1Bis !== undefined) {
      objectSetSollArbeitszeitenTag.pause1Bis = dayjs(values.pause1Bis).second(0).format("HH:mm");
    } else {
      if (detailData.evenData.pause1Bis !== undefined) {
        objectSetSollArbeitszeitenTag.pause1Bis = detailData.evenData.pause1Bis;
      } else {
        objectSetSollArbeitszeitenTag.pause1Bis = "00:00";
      }
    }
    if (values.pause2Von !== undefined) {
      objectSetSollArbeitszeitenTag.pause2Von = dayjs(values.pause2Von).second(0).format("HH:mm");
    } else {
      if (detailData.evenData.pause2Von !== undefined) {
        objectSetSollArbeitszeitenTag.pause2Von = detailData.evenData.pause2Von;
      } else {
        objectSetSollArbeitszeitenTag.pause2Von = "00:00";
      }
    }
    if (values.pause2Bis !== undefined) {
      objectSetSollArbeitszeitenTag.pause2Bis = dayjs(values.pause2Bis).second(0).format("HH:mm");
    } else {
      if (detailData.evenData.pause2Bis !== undefined) {
        objectSetSollArbeitszeitenTag.pause2Bis = detailData.evenData.pause2Bis;
      } else {
        objectSetSollArbeitszeitenTag.pause2Bis = "00:00";
      }
    }

    if (values.pause !== undefined && values.pause !== null) {
      objectSetSollArbeitszeitenTag.pause = dayjs(values.pause).startOf("minute").format("HH:mm");
    } else {
      objectSetSollArbeitszeitenTag.pause = detailData.evenData.pause;
    }

    if (Object.keys(detailData.evenData).length !== 0) {
      objectSetSollArbeitszeitenTag.dbid = detailData.evenData.id;
    } else {
      objectSetSollArbeitszeitenTag.dbid = 0;
    }
    objectSetSollArbeitszeitenTag.maid = detailData.detailMAID;
    objectSetSollArbeitszeitenTag.pauseFix = displayDetailPause;
    objectSetSollArbeitszeitenTag.uhrzeitVon = "" + dayjs(values.ArbeitszeitAb).startOf("minute").format("HH:mm");
    objectSetSollArbeitszeitenTag.uhrzeitBis = "" + dayjs(values.ArbeitszeitBis).startOf("minute").format("HH:mm");

    objectSetSollArbeitszeitenTag.arbeitszeit = "00:00";
    objectSetSollArbeitszeitenTag.datum = "" + dayjs(detailData.aktTagDetail).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    objectSetSollArbeitszeitenTag.datumVon = "" + dayjs(values.gueltigAb).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";

    if (displayDetailBisEndlosArbeitszeit) {
      objectSetSollArbeitszeitenTag.datumBis = "" + dayjs("2099-12-31").format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z"; //.day(31).month(11).year(2099)
    } else {
      objectSetSollArbeitszeitenTag.datumBis = "" + dayjs(values.gueltigBis).format("YYYY-MM-DDTHH:mm:ss.SSS") + "Z";
    }

    fetchData(getQuerySetSollArbeitszeit({ userToken, filialToken, objectSetSollArbeitszeitenTag })).then(function (
      response
    ) {
      // TODO: Response ausgeben ob erfolgreich oder Fehler
      let temp = response.data?.data?.setSollArbeitszeit;
      checkQueryRespone(temp.data);
    });
  };

  const handleCancelEinstellungen = () => {
    setDisplaySettingsModalAbwesenheit(false);
    setDisplaySettingsModalArbeitszeit(false);
  };

  /**
   * Text fuer Tooltips generieren
   * @param event
   */
  const generateTooltipText = (event: EventImpl) => {
    let returnTooltipText = "<div style='margin: 8px'>";
    if (
      event.extendedProps.detailTyp.includes("Abwesenheit") ||
      event.extendedProps.detailTyp.includes("Arbeitszeit")
    ) {
      // returnTooltipText += "  ID: " + event.extendedProps.evenData.id + "_<br/>";
    }
    returnTooltipText += "" + dayjs(event.extendedProps.aktTagDetail).format("DD.MM.YYYY") + "<br/>";
    if (
      event.extendedProps.detailTyp.includes("Abwesenheit") ||
      event.extendedProps.detailTyp.includes("Arbeitszeit")
    ) {
      returnTooltipText += event.extendedProps.detailMA + " in der Filiale " + event.extendedProps.detailFiliale;
    }
    returnTooltipText += "<hr>";
    if (event.extendedProps.detailTyp.includes("Abwesenheit")) {
      returnTooltipText +=
        event.extendedProps.detailAbwesenheitsTyp +
        "<br/> Gültig ab " +
        dayjs(event.extendedProps.detailDatumVon).format("DD.MM.YYYY") +
        " bis " +
        dayjs(event.extendedProps.detailDatumBis).format("DD.MM.YYYY") +
        "<br/>  " +
        event.extendedProps.detailBemerkung;
    } else if (event.extendedProps.detailTyp.includes("Arbeitszeit")) {
      if (Object.keys(event.extendedProps.evenData).length === 0) {
        returnTooltipText += "Soll-Arbeitszeit: " + " - " + "<br/>Pause: -";
      } else {
        returnTooltipText +=
          "Soll-Arbeitszeit: " +
          event.extendedProps.detailUhrzeitVon +
          " - " +
          event.extendedProps.detailUhrzeitBis +
          "<br/>Pause: " +
          event.extendedProps.detailPause;
      }
    } else if (event.extendedProps.detailTyp.includes("Summe")) {
      returnTooltipText += "Summeneintrag des Tages";
    } else {
      returnTooltipText += "Kein Eintrag ";
    }
    return returnTooltipText + "</div>";
  };

  /**
   * Listener erzeugen um Contextmenu zu ueberschreiben
   * @param eventGot
   */
  const handleEventRender = (eventGot: EventMountArg) => {
    eventGot.el.addEventListener("contextmenu", (evt) => createContextMenu(eventGot.event, evt));
  };

  /**
   * Listener erzeugen um Tooltip in der Resource anzuzeigen
   * @param eventGot
   */
  const handleResourceRender = (eventGot: ColCellMountArg, typeGot: string) => {
    eventGot.el.addEventListener("mouseover", () =>
      tippy(eventGot.el, {
        content: "<div style='margin: 8px'>Summe der " + typeGot + " des Mitarbeiters im entsprechenden Zeitraum</div>",
        placement: "top",
        interactive: false,
        arrow: true,
        theme: "ecut",
        appendTo: document.body,
        allowHTML: true,
        duration: [1, 1],
        offset: [0, 10],
      })
    );
  };

  /**
   * Listener erzeugen um Tooltip in der Resource anzuzeigen
   * @param eventGot
   */
  const handleResourceHeaderRender = (eventGot: ColHeaderMountArg, typeGot: string) => {
    eventGot.el.addEventListener("mouseover", () =>
      tippy(eventGot.el, {
        content: "<div style='margin: 8px'>Die Summen der " + typeGot + " des Zeitraumes</div>",
        placement: "top",
        interactive: false,
        arrow: true,
        theme: "ecut",
        appendTo: document.body,
        allowHTML: true,
        duration: [1, 1],
        offset: [0, 10],
      })
    );
  };

  /**
   * Handler fuer Contextmenueintraege
   */
  const handleClickMenu = () => {
    setDisplayModalMenuPosX(-500);
    setDisplayModalMenuPosY(-500);
    setDisplayModalMenu(false);
  };

  /**
   * Kopieren an APi senden
   * @param typeKopie tag woche oder monat
   */
  const sendZeitraumKopieren = (typeKopie: string) => {
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");
    let objectKopiere = {} as typeKopiereZeitraum;
    if (typeKopie.includes("Tag")) objectKopiere = globalMemory.kopieTag;
    else if (typeKopie.includes("Woche")) objectKopiere = globalMemory.kopieWoche;
    else if (typeKopie.includes("Monat")) objectKopiere = globalMemory.kopieMonat;
    fetchData(getQueryKopiereZeitraum({ userToken, objectKopiere })).then(function (response) {
      // TODO: Response ausgeben ob erfolgreich oder Fehler
      let temp = response.data?.data?.copySollArbeitszeit;
      checkQueryRespone(temp.data);
    });
  };

  const handleClickKopiereTag = (eventGot: EventImpl) => {
    globalMemory.tagKopiert = JSON.stringify(eventGot.extendedProps);
    globalMemory.kopieTag.quellDatumVon =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieTag.quellDatumBis =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieTag.quellMaid = eventGot.extendedProps.detailMAID;
    globalMemory.kopieTag.quellMa = eventGot.extendedProps.detailMA;
    globalMemory.kopieTag.quellFilialToken = "" + filialToken;
    globalMemory.tagKopiertQuellMA = eventGot.extendedProps.detailMA;

    handleClickMenu();
  };

  const handleClickEinfuegenTag = (eventGot: EventImpl) => {
    globalMemory.kopieTag.zielDatumVon =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieTag.zielMaid = eventGot.extendedProps.detailMAID;
    globalMemory.kopieTag.zielFilialToken = "" + filialToken;

    handleClickMenu();
    //setDisplayModalBestaetigungKopierenTyp("tag");
    globalMemory.kopieTyp = "Tag";

    let modelContent: typeModalBestaetigungCopy = {} as typeModalBestaetigungCopy;
    modelContent.titel = "Tag kopieren";
    modelContent.maVon = globalMemory.kopieTag.quellMa;
    modelContent.maZu = eventGot.extendedProps.detailMA;
    // @ts-ignore
    modelContent.datumVon = dayjs(globalMemory.kopieTag.quellDatumVon).format("DD.MM.YYYY");
    // @ts-ignore
    modelContent.datumZu = dayjs(globalMemory.kopieTag.zielDatumVon).format("DD.MM.YYYY");
    setDisplayModalCopyContent(modelContent);

    setDisplayModalBestaetigungKopieren(true);
  };

  const handleClickKopiereWoche = (eventGot: EventImpl) => {
    globalMemory.wocheKopiert = JSON.stringify(eventGot.extendedProps);
    globalMemory.kopieWoche.quellDatumVon =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).day(1).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieWoche.quellDatumBis =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).day(7).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieWoche.quellMaid = eventGot.extendedProps.detailMAID;
    globalMemory.kopieWoche.quellMa = eventGot.extendedProps.detailMA;
    globalMemory.kopieWoche.quellFilialToken = "" + filialToken;
    globalMemory.wocheKopiertQuellMA = eventGot.extendedProps.detailMA;
    handleClickMenu();
  };

  const handleClickEinfuegenWoche = (eventGot: EventImpl) => {
    globalMemory.kopieWoche.zielDatumVon =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).day(1).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieWoche.zielMaid = eventGot.extendedProps.detailMAID;
    globalMemory.kopieWoche.zielFilialToken = "" + filialToken;

    handleClickMenu();
    //setDisplayModalBestaetigungKopierenTyp("woche");
    globalMemory.kopieTyp = "Woche";
    let modelContent: typeModalBestaetigungCopy = {} as typeModalBestaetigungCopy;
    modelContent.titel = "Woche kopieren";
    modelContent.maVon = globalMemory.kopieWoche.quellMa;
    modelContent.maZu = eventGot.extendedProps.detailMA;
    // @ts-ignore
    modelContent.datumVon = "KW " + dayjs(globalMemory.kopieWoche.quellDatumVon).week();
    // @ts-ignore
    modelContent.datumZu = "KW " + dayjs(globalMemory.kopieWoche.zielDatumVon).week();
    setDisplayModalCopyContent(modelContent);

    setDisplayModalBestaetigungKopieren(true);
  };

  const handleClickKopiereMonat = (eventGot: EventImpl) => {
    globalMemory.monatKopiert = JSON.stringify(eventGot.extendedProps);
    globalMemory.kopieMonat.quellDatumVon =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).date(1).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieMonat.quellDatumBis =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).endOf("month").format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieMonat.quellMaid = eventGot.extendedProps.detailMAID;
    globalMemory.kopieMonat.quellMa = eventGot.extendedProps.detailMA;
    globalMemory.kopieMonat.quellFilialToken = "" + filialToken;
    globalMemory.monatKopiertQuellMA = eventGot.extendedProps.detailMA;
    handleClickMenu();
  };

  const handleClickEinfuegenMonat = (eventGot: EventImpl) => {
    globalMemory.kopieMonat.zielDatumVon =
      "" + dayjs(eventGot.extendedProps.aktTagDetail).date(1).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z";
    globalMemory.kopieMonat.zielMaid = eventGot.extendedProps.detailMAID;
    globalMemory.kopieMonat.zielFilialToken = "" + filialToken;

    handleClickMenu();
    //setDisplayModalBestaetigungKopierenTyp("monat");
    globalMemory.kopieTyp = "Monat";
    let modelContent: typeModalBestaetigungCopy = {} as typeModalBestaetigungCopy;
    modelContent.titel = "Monat kopieren";
    modelContent.maVon = globalMemory.kopieMonat.quellMa;
    modelContent.maZu = eventGot.extendedProps.detailMA;
    // @ts-ignore
    modelContent.datumVon = dayjs(globalMemory.kopieMonat.quellDatumVon).locale("de").format("MMMM");
    // @ts-ignore
    modelContent.datumZu = dayjs(globalMemory.kopieMonat.zielDatumVon).locale("de").format("MMMM");
    setDisplayModalCopyContent(modelContent);

    setDisplayModalBestaetigungKopieren(true);
  };

  const handleClickErzeugeArbeitszeit = (eventGot: EventImpl) => {
    setDetailData({
      resourceId: "",
      title: "+",
      start: eventGot.extendedProps.start,

      aktTagDetail: eventGot.extendedProps.aktTagDetail,
      end: eventGot.extendedProps.end,
      color: colorLeerEvent,
      textColor: colorLeerEvent,
      allDay: true,
      order: "6",
      detailTyp: "Arbeitszeit",
      detailMA: "" + eventGot.extendedProps.detailMA,
      detailMAID: "" + eventGot.extendedProps.detailMAID,
      detailFiliale: "" + eventGot.extendedProps.detailFiliale,
      detailAbwesenheitsTyp: APIEnumZeitTyp.URLAUB,
      detailDatumVon: "" + dayjs(eventGot.extendedProps.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z",
      detailDatumBis: "" + dayjs(eventGot.extendedProps.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z",
      detailUhrzeitVon: "00:00",
      detailUhrzeitBis: "00:00",
      detailPausefix: true,
      detailPause: "00:00",
      detailArbeitszeit: "00:00",
      detailPause1Von: "00:00",
      detailPause2Von: "00:00",
      detailPause1Bis: "00:00",
      detailPause2Bis: "00:00",
      detailBemerkung: "",
      detailHalberTag: false,
      detailTurnusTyp: APIEnumTurnusTyp.JEDEWOCHE,
      evenData: {},
    });
    //openDetail(eventGot);
    handleClickMenu();
    setDisplayNewEvent(true);
    setDisplayFirstOpen(true);
    setDisplaySettingsModalArbeitszeit(true);
  };

  const handleClickErzeugeAbwesenheit = (eventGot: EventImpl) => {
    setDetailData({
      resourceId: "",
      title: "+",
      start: eventGot.extendedProps.start,

      aktTagDetail: eventGot.extendedProps.aktTagDetail,
      end: eventGot.extendedProps.end,
      color: colorLeerEvent,
      textColor: colorLeerEvent,
      allDay: true,
      order: "6",
      detailTyp: "Abwesenheit",
      detailMA: "" + eventGot.extendedProps.detailMA,
      detailMAID: "" + eventGot.extendedProps.detailMAID,
      detailFiliale: "" + eventGot.extendedProps.detailFiliale,
      detailAbwesenheitsTyp: APIEnumZeitTyp.URLAUB,
      detailDatumVon: "" + dayjs(eventGot.extendedProps.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z",
      detailDatumBis: "" + dayjs(eventGot.extendedProps.aktTagDetail).format("YYYY-MM-DDTHH:MM:ss.SSS") + "Z",
      detailUhrzeitVon: "00:00",
      detailUhrzeitBis: "00:00",
      detailBemerkung: "",
      detailHalberTag: false,
      detailTurnusTyp: APIEnumTurnusTyp.TAEGLICH,
      evenData: {},
    });
    setDisplayAbwesenheitSelectSchule(false);
    setDisplayAbwesenheitSelectDisabled(false);
    handleClickMenu();
    setDisplayFirstOpen(true);
    setDisplayNewEvent(true);

    setDisplaySettingsModalAbwesenheit(true);
  };

  const handleClickUrlaubsAnspruch = (eventGot: EventImpl) => {
    let userToken = getWertFromlocalStorage("loginInfo", "userToken");

    let jahr = eventGot.start?.getFullYear();
    let maid = eventGot.extendedProps.detailMAID;
    fetchData(getQuerGetUrlaubsanspruch({ userToken, filialToken, jahr, maid } as QueryParams)).then(function (
      response
    ) {
      // TODO: Response ausgeben ob erfolgreich oder Fehler
      let temp = response.data?.data?.getUrlaubsanspruch;

      handleClickMenu();
      if (temp.header.success) {
        //  setDisplayUrlaubsanspruch(temp.data.urlaubsanspruch);
        globalMemory.urlaubsanspruch = temp.data.urlaubsanspruch;
        // @ts-ignore
        globalMemory.urlaubsjahr = jahr;
        // @ts-ignore
        globalMemory.token = filialToken;
        globalMemory.maid = maid;
        globalMemory.ma = eventGot.extendedProps.detailMA;
        setDisplayModalUrlaubsanspruch(true);
      }
    });
  };

  /**
   * Contextmenueintraege erzeugen
   * @param eventGot
   */
  const createMenuForEvent = (eventGot: EventImpl) => {
    let aktMA = eventGot.extendedProps.detailMA;

    // @ts-ignore
    let aktKW = dayjs(eventGot.start).week();

    let items: ItemType[] = [
      {
        key: "01",
        label: <div>Arbeitszeit {aktMA} eintragen</div>,
        onClick: function () {
          handleClickErzeugeArbeitszeit(eventGot);
        },
        icon: <EditTwoTone />,
      },
      {
        key: "02",
        label: <div>Abwesenheit {aktMA} eintragen</div>,
        onClick: function () {
          handleClickErzeugeAbwesenheit(eventGot);
        },
        icon: <EditTwoTone />,
      },
      {
        type: "divider",
      },
      {
        key: "03",
        label: <div>Neuen Mitarbeiter / Filiale einblenden</div>,
        onClick: function () {
          handleClickMenu();
          setDisplayModalMenu(false);
          if (!isVerbund) {
            setDisplayAbwesenheitSelectMAFA(true);
          } else {
            setDisplayAbwesenheitSelectMAFA(false);
          }
          setDisplayModalSelectMAFA(true);
        },
        icon: <EyeTwoTone />,
      },

      {
        type: "divider",
      },
      {
        key: "04",
        label: <div>Urlaubsanspruch {aktMA}</div>,
        onClick: function () {
          handleClickUrlaubsAnspruch(eventGot);
        },
        icon: <EyeTwoTone />,
      },
      {
        type: "divider",
      },
      {
        key: "1",
        label: (
          <div>
            Tag kopieren ({dayjs(eventGot.start).format("DD.MM.YYYY")} / {aktMA})
          </div>
        ),
        onClick: function () {
          handleClickKopiereTag(eventGot);
        },
        icon: <CopyTwoTone />,
      },
      {
        key: "2",
        label: (
          <div>
            Woche kopieren (KW{aktKW} / {aktMA})
          </div>
        ),
        onClick: function () {
          handleClickKopiereWoche(eventGot);
        },
        icon: <CopyTwoTone />,
      },
      {
        key: "3",
        label: (
          <div>
            Monat kopieren ({dayjs(eventGot.start).format("MM.YYYY")} / {aktMA})
          </div>
        ),
        icon: <CopyTwoTone />,
        onClick: function () {
          handleClickKopiereMonat(eventGot);
        },
      },
      {
        type: "divider",
      },
      {
        key: "4",
        label: <div>Tag einfügen</div>,
        disabled: globalMemory.tagKopiert === "",
        onClick: function () {
          handleClickEinfuegenTag(eventGot);
        },
        icon: <SnippetsTwoTone />,
      },
      {
        key: "5",
        label: <div>Woche einfügen</div>,
        disabled: globalMemory.wocheKopiert === "",
        onClick: function () {
          handleClickEinfuegenWoche(eventGot);
        },
        icon: <SnippetsTwoTone />,
      },
      {
        key: "6",
        label: <div>Monat einfügen</div>,
        disabled: globalMemory.monatKopiert === "",
        onClick: function () {
          handleClickEinfuegenMonat(eventGot);
        },
        icon: <SnippetsTwoTone />,
      },
    ];

    if (!isCompanyAdministrator()) {
      items.splice(5, 2);
    }

    return items;
  };

  /**
   * Contextmenu unterdruecken und eigenes anzeigen
   * @param eventGot
   * @param evt
   */
  const createContextMenu = (eventGot: EventImpl, evt: MouseEvent) => {
    evt.preventDefault();
    if (isVerbund && eventGot.extendedProps.detailTyp.includes("Summe")) {
    } else {
      setDisplayModalMenuItems(createMenuForEvent(eventGot));
      // DIV wieder verschieben
      setDisplayModalMenuPosX(evt.x);
      setDisplayModalMenuPosY(evt.y);

      setDisplayModalMenu(true);
    }
  };

  document.addEventListener("click", (evt) => {
    if (
      displayModalMenu &&
      !(
        evt.clientX >= displayModalMenuPosX &&
        evt.clientX <= displayModalMenuPosX + 450 &&
        evt.clientY >= displayModalMenuPosY &&
        evt.clientY <= displayModalMenuPosY + 350
      )
    )
      setDisplayModalMenu(false);
  });

  if (type) {
    return (
      <Content className="global-layout-background global-content" style={{ marginBottom: "5px", overflowY: "auto" }}>
        <Spin tip="Loading" size="large" spinning={isLoading}>
          <p></p>
          <Row justify="end" style={{ position: "absolute", right: 0, top: 0, marginRight: "5px" }}>
            <Button type="primary" onClick={switchCalendarToWeek}>
              Wochenansicht
            </Button>
            &nbsp;
            <Button type="primary" onClick={switchCalendarToMonth}>
              Monatsansicht
            </Button>
          </Row>

          <StyleWrapper style={{ marginRight: "5px" }}>
            <FullCalendar
              height={"auto"}
              plugins={[resourceTimelinePlugin, interactionPlugin]}
              initialView="customMonth"
              weekends={true}
              locale={"de"}
              handleWindowResize={true}
              firstDay={1}
              schedulerLicenseKey="0143926529-fcs-1689324442"
              views={{
                customWeek: {
                  type: "resourceTimeline",
                  duration: { weeks: 1 },
                  slotDuration: { days: 1 },
                  buttonText: "Wochenansicht",
                },
                customMonth: {
                  type: "resourceTimeline",
                  duration: { months: 1 },
                  slotDuration: { days: 1 },
                  buttonText: "Monatsansicht",
                },
              }}
              initialDate={"" + aktDate.format("YYYY-MM-DD")}
              nowIndicator={false}
              headerToolbar={{
                left: "",
                center: "title",
                right: "",
              }}
              resourceAreaColumns={[
                { field: "title", headerContent: "", width: 150 },
                {
                  field: "sumA",
                  headerContent: "A",
                  width: 40,
                  headerClassNames: "testCSS",
                  cellDidMount: function (arg) {
                    handleResourceRender(arg, "Arbeitszeit");
                  },
                  headerDidMount: function (arg) {
                    handleResourceHeaderRender(arg, "Arbeitszeit");
                  },
                },
                {
                  field: "sumK",
                  headerContent: "K",
                  width: 20,
                  cellDidMount: function (arg) {
                    handleResourceRender(arg, "Krankheitstage");
                  },

                  headerDidMount: function (arg) {
                    handleResourceHeaderRender(arg, "Krankheit");
                  },
                },
                {
                  field: "sumU",
                  headerContent: "U",
                  width: 20,
                  headerClassNames: ["testCSS"],
                  cellDidMount: function (arg) {
                    handleResourceRender(arg, "Urlaubstage");
                  },
                  headerDidMount: function (arg) {
                    handleResourceHeaderRender(arg, "Urlaub");
                  },
                },
              ]}
              buttonText={{ today: "Heute" }}
              businessHours={[{ daysOfWeek: [1, 2, 3, 4, 5] }]}
              slotLabelFormat={[{ week: "short" }, { weekday: "short" }, { day: "numeric" }]}
              resourceAreaWidth={350}
              slotMinWidth={25}
              expandRows={true}
              eventOrder={"order"}
              contentHeight={800}
              resources={arrMitarbeiterFilialen}
              events={arrEvents}
              resourcesInitiallyExpanded={false}
              ref={calendarRef}
              eventDidMount={handleEventRender}
              eventMouseEnter={function (info) {
                tippy(info.el, {
                  content: "" + generateTooltipText(info.event),
                  placement: "top",
                  interactive: false,
                  arrow: true,
                  theme: "ecut",
                  appendTo: document.body,
                  allowHTML: true,
                  duration: [1, 1],
                  offset: [0, 10],
                });
              }}
              dateClick={function () {}}
              eventClick={function (arg) {
                openDetail(arg.event);
              }}
            />
          </StyleWrapper>

          <Row justify="start" style={{ position: "absolute", left: 36, top: 0 }}>
            {aktCalendarView === "Wochenansicht" ? (
              <IWDatePicker
                onClick={handleChangeDate}
                range={APIEnumDatepicker.WEEK}
                isLabel={"false"}
                defaultDate={dayjs(dateData.startDateData)}
              />
            ) : null}
            {aktCalendarView.includes("Monatsansicht") ? (
              <IWDatePicker
                onClick={handleChangeDate}
                range={APIEnumDatepicker.MONTH}
                defaultDate={dayjs(dateData.startDateData)}
                isLabel={"false"}
              />
            ) : null}
          </Row>
          {!isAlle ? (
            <Button
              type="primary"
              shape="circle"
              style={{ position: "absolute", left: 0, top: 0 }}
              onClick={function () {
                if (!isVerbund) {
                  setDisplayAbwesenheitSelectMAFA(true);
                } else {
                  setDisplayAbwesenheitSelectMAFA(false);
                }
                setDisplayModalSelectMAFA(true);
              }}
            >
              +
            </Button>
          ) : null}
          {/**
            <Button
              type="primary"
              shape="circle"
              style={{ position: "absolute", left: 200, top: 0 }}
              onClick={function () {
                test();
              }}
            >
              X
            </Button>
          **/}
        </Spin>
        {/*-------------------------------------------------------------*/}
        {/*-------------------- Urlaubsanspruchsmodal-------------------*/}
        {/*-------------------------------------------------------------*/}
        <IWUrlaubsanspruchmodal
          displayModal={displayModalUrlaubsanspruch}
          closeAction={setDisplayModalUrlaubsanspruch}
          displayUrlaubsanspruch={displayUrlaubsanspruch}
        ></IWUrlaubsanspruchmodal>

        {/*-------------------------------------------------------------*/}
        {/*-------------------- Kontextmenu ----------------------------*/}
        {/*-------------------------------------------------------------*/}
        <Dropdown open={displayModalMenu} menu={{ items: displayModalMenuItems }} trigger={["click"]} autoFocus={true}>
          <p
            style={{
              position: "absolute",
              left: displayModalMenuPosX - 20,
              top: displayModalMenuPosY - 20,
              width: 1,
              height: 1,
            }}
          ></p>
        </Dropdown>
        {/*-------------------------------------------------------------*/}
        {/*------------------------ ErrorModal -------------------------*/}
        {/*-------------------------------------------------------------*/}
        <Modal
          title={displayModalErrorContent.titel}
          width={400}
          open={displayModalError}
          onCancel={function () {
            setDisplayModalError(false);
          }}
          onOk={function () {
            setDisplayModalError(false);
          }}
          okText={displayModalErrorContent.okButton}
          cancelText={"Abbrechen"}
          maskClosable={false}
          closable={false}
          destroyOnClose={true}
          footer={[
            <Button
              key="submit"
              type="primary"
              onClick={function () {
                setDisplayModalError(false);
              }}
            >
              {displayModalErrorContent.okButton}
            </Button>,
          ]}
        >
          <Divider></Divider>
          <Space direction="vertical" size="small">
            <Space direction="horizontal" size="small">
              <p dangerouslySetInnerHTML={{ __html: displayModalErrorContent.text }} />
            </Space>
            <br />
          </Space>
        </Modal>

        {/*-------------------------------------------------------------*/}
        {/*-------------------- Modal zur Bestaetigung ------------------*/}
        {/*-------------------------------------------------------------*/}
        <Modal
          title={displayModalBestaetigungContent.titel}
          width={400}
          open={displayModalBestaetigung}
          onCancel={function () {
            abbrechenAbwesenheit();
          }}
          onOk={handleOKBestaetigungsModal}
          okText={displayModalBestaetigungContent.okButton}
          okButtonProps={{ style: { backgroundColor: okButtonColor } }}
          cancelText={"Abbrechen"}
          maskClosable={false}
          closable={false}
          destroyOnClose={true}
        >
          <Divider></Divider>
          <Space direction="vertical" size="small">
            <Space direction="horizontal" size="small">
              <SmileTwoTone
                style={{
                  marginLeft: "100px",
                }}
              />
              {displayModalBestaetigungContent.ma}
            </Space>
            <Space direction="horizontal" size="small">
              <CalendarTwoTone
                style={{
                  marginLeft: "100px",
                }}
              />
              {displayModalBestaetigungContent.datum}
            </Space>
            <br />
            <Space direction="horizontal" size="small">
              <p dangerouslySetInnerHTML={{ __html: displayModalBestaetigungContent.text }} />
            </Space>
            <br />
          </Space>
        </Modal>
        {/*-------------------------------------------------------------*/}
        {/*-----------Modal zur Bestaetigung Kopieren ------------------*/}
        {/*-------------------------------------------------------------*/}
        <Modal
          title={displayModalCopyContent.titel}
          width={400}
          open={displayModalBestaetigungKopieren}
          onCancel={function () {
            setDisplayModalBestaetigungKopieren(false);
          }}
          onOk={handleOkKopierenBestaetigt}
          okText={"Ja"}
          cancelText={"Nein"}
          maskClosable={false}
          closable={false}
          destroyOnClose={true}
          footer={false}
        >
          <Divider></Divider>
          <Space direction="vertical" size="small">
            <div
              style={{
                position: "absolute",
                left: 30,
                top: 80,
              }}
            >
              <u></u>
            </div>
            <div
              style={{
                position: "absolute",
                left: 220,
                top: 80,
              }}
            >
              <u></u>
            </div>
            <SmileTwoTone
              style={{
                position: "absolute",
                left: 30,
                top: 122,
              }}
            />
            <div
              style={{
                position: "absolute",
                left: 50,
                top: 117,
              }}
            >
              {displayModalCopyContent.maVon}
            </div>

            <SmileTwoTone
              style={{
                position: "absolute",
                left: 220,
                top: 122,
              }}
            />
            <div
              style={{
                position: "absolute",
                left: 240,
                top: 117,
              }}
            >
              {displayModalCopyContent.maZu}
            </div>

            <ArrowRightOutlined
              style={{ fontSize: "20px", color: "#08c", position: "absolute", left: 180, top: 135 }}
            />

            <CalendarTwoTone
              style={{
                position: "absolute",
                left: 30,
                top: 152,
              }}
            />
            <div
              style={{
                position: "absolute",
                left: 50,
                top: 148,
              }}
            >
              {displayModalCopyContent.datumVon}{" "}
            </div>
            <CalendarTwoTone
              style={{
                position: "absolute",
                left: 220,
                top: 152,
              }}
            />
            <div
              style={{
                position: "absolute",
                left: 240,
                top: 148,
              }}
            >
              {displayModalCopyContent.datumZu}
            </div>

            <br />
            <Space direction="horizontal" size="small"></Space>
            <br />
          </Space>
          <Button
            type="primary"
            onClick={function () {
              setDisplayModalBestaetigungKopieren(false);
            }}
            style={{
              position: "absolute",
              left: 260,
              top: 220,
              backgroundColor: "white",
              color: "black",
            }}
          >
            Nein
          </Button>{" "}
          <Button
            type="primary"
            onClick={handleOkKopierenBestaetigt}
            style={{
              position: "absolute",
              left: 330,
              top: 220,
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Ja
          </Button>
        </Modal>
        {/*-------------------------------------------------------------*/}
        {/*-------------------- MA Filiale hinzufuegen-------------------*/}
        {/*-------------------------------------------------------------*/}
        <Modal
          title={"Mitarbeiter / Filiale hinzufügen"}
          width={400}
          open={displayModalSelectMAFA}
          onCancel={handleCancelSelectMAFA}
          okText={"Speichern"}
          footer={null}
          maskClosable={false}
          closable={false}
          destroyOnClose={true}
        >
          <Divider></Divider>
          <Form
            name="basic"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 450 }}
            initialValues={{ remember: true }}
            onFinish={handleOkSelectMAFA}
            autoComplete="off"
          >
            <Form.Item
              label="Mitarbeiter"
              name="MAID"
              rules={[{ required: true, message: "Mitarbeiter wählen" }]}
              style={{ marginRight: 5, width: 400 }}
            >
              <Select style={{ width: 250, marginLeft: 5 }} options={generiereMASelect()} showSearch={true} />
            </Form.Item>
            <Form.Item
              label="Filiale"
              name="FAID"
              style={{ marginRight: 5, width: 400 }}
              rules={[{ required: false, message: "Filiale wählen" }]}
            >
              <Select
                style={{ marginLeft: 5, width: 250 }}
                options={generiereFilialSelect()}
                defaultValue={function () {
                  return getFilialnameFromID(aktFilialToken, filialen);
                }}
                disabled={displayAbwesenheitSelectMAFA}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                onClick={handleCancelSelectMAFA}
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#4096ff",
                  left: 147,
                  top: 30,

                  marginLeft: "50px",
                }}
              >
                Abbrechen
              </Button>{" "}
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  position: "absolute",
                  left: 300,
                  top: 30,
                  backgroundColor: "#4096ff",
                  color: "#f5f5f5",
                }}
              >
                Ok
              </Button>
            </Form.Item>
          </Form>
        </Modal>
        {/*-------------------------------------------------------------*/}
        {/*--------------------- Abwesenheitsfenster--------------------*/}
        {/*-------------------------------------------------------------*/}
        <IWAbwesenheitsmodal
          displaySettingsModalAbwesenheit={displaySettingsModalAbwesenheit}
          handleOkAbwesenheit={handleOkModalBestaetigung}
          detailData={detailData}
          handleChangeAbwesenheitTyp={handleChangeAbwesenheitTyp}
          displayDetailBisEndlosAbwesenheit={displayDetailBisEndlosAbwesenheit}
          setDisplayDetailBisEndlosAbwesenheit={setDisplayDetailBisEndlosAbwesenheit}
          displayDetailEditZeitKontext={displayDetailEditZeitKontext}
          displayAbwesenheitSelectSchule={displayAbwesenheitSelectSchule}
          setDisplayDetailEditZeitKontext={setDisplayDetailEditZeitKontext}
          handleOkDelete={handleOkDelete}
          handleCancelEinstellungen={handleCancelEinstellungen}
          displayNewEvent={displayNewEvent}
          displayAbwesenheitSelect={displayAbwesenheitSelect}
          displayFirstOpen={displayFirstOpen}
          setDisplayFirstOpen={setDisplayFirstOpen}
          isUrlaubOderKrank={isUrlaubOderKrank}
          setisUrlaubOderKrank={setisUrlaubOderKrank}
        ></IWAbwesenheitsmodal>

        {/*-------------------------------------------------------------*/}
        {/*-------------------- Arbeitszeitenfenster--------------------*/}
        {/*-------------------------------------------------------------*/}

        <IWArbeitszeitenmodal
          displaySettingsModalArbeitszeit={displaySettingsModalArbeitszeit}
          handleOkArbeitszeiten={handleOkModalBestaetigung}
          detailData={detailData}
          displayDetailBisEndlosArbeitszeit={displayDetailBisEndlosArbeitszeit}
          setDisplayDetailBisEndlosArbeitszeit={setDisplayDetailBisEndlosArbeitszeit}
          displayDetailPause={displayDetailPause}
          setDisplayDetailPause={setDisplayDetailPause}
          displayDetailEditZeitKontext={displayDetailEditZeitKontext}
          setDisplayDetailEditZeitKontext={setDisplayDetailEditZeitKontext}
          handleOkDelete={handleOkDelete}
          handleCancelEinstellungen={handleCancelEinstellungen}
          displayNewEvent={displayNewEvent}
          displayFirstOpen={displayFirstOpen}
          setDisplayFirstOpen={setDisplayFirstOpen}
        ></IWArbeitszeitenmodal>
      </Content>
    );
  }
  return (
    <Content className="global-layout-background global-content">
      <div className={style.headline}>
        <Result
          title="Noch kein Verbund oder Filiale ausgewählt"
          extra={[
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <text style={{ textAlign: "left" }}>
                <div>Bitte eine Filiale oder einen Vebund auswählen</div>
              </text>
            </div>,
          ]}
        />
      </div>
    </Content>
  );
};

export default SollArbeitszeiten;
export const StyleWrapper = styled.div`
  .fc-timeline-header-row a {
    color: #6b6b6b;
    opacity: 1;
    font-weight: bold;
    font-size: 14px;
  }

  .fc-datagrid-cell-frame {
    color: #6b6b6b;
    opacity: 1;
    font-weight: bold;
    font-size: 14px;
  }

  .fc-timeline-event {
    opacity: 1;
    height: 30px;
    font-size: 14px;
  }
  .fc-event-title-container {
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
  }

  .fc-datagrid-header {
    height: 50px !important;
  }

  .fc-datagrid-cell-frame {
  }

  .fc-resource {
  }
  .fc-day-today {
    background-color: #e1f0fc;
    opacity: 1;
  }
  .fc-day {
    color: blue;
  }

  .fc-day-past {
  }

  .fc-event {
    width: 95%;
  }
`;
