import React, { useCallback, useState } from "react";
import { convertToLocalDate } from "../../../utils/functionsDatum";
import dayjs from "dayjs";
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Switch, TimePicker } from "antd";
import locale from "antd/es/date-picker/locale/de_DE";
import style from "../Datev/Datev.module.css";
import { APIEnumTurnusTyp } from "../../../types/apiTypes";
import { globalMemory } from "../../../globals";

const IWArbeitszeitenmodal = (props: any) => {
  let detailData = props.detailData;
  let [form] = Form.useForm(); //TODO CK

  if (detailData.detailPause === null) detailData.detailPause = "00:00";

  if (props.displayNewEvent) {
    props.setDisplayDetailEditZeitKontext(true);
  }

  const setDisplayFirstOpen = useCallback(() => {
    props.setDisplayFirstOpen(false);
  }, [props.setDisplayFirstOpen]);

  if (props.displayFirstOpen) {
    form.resetFields();
    setDisplayFirstOpen();
  }

  const setDisplayDetailBisEndlosArbeitszeit = useCallback(
    (value) => {
      props.setDisplayDetailBisEndlosArbeitszeit(value);
    },
    [props.setDisplayDetailBisEndlosArbeitszeit]
  );

  const setDisplayDetailPause = useCallback(
    (value) => {
      props.setDisplayDetailPause(value);
    },
    [props.setDisplayDetailPause]
  );

  const setDisplayDetailEditZeitKontext = useCallback(
    (value) => {
      props.setDisplayDetailEditZeitKontext(value);
    },
    [props.setDisplayDetailEditZeitKontext]
  );

  const handleOkArbeitszeiten = useCallback(
    (values) => {
      props.handleOkArbeitszeiten(values, false);
    },
    [props.handleOkArbeitszeiten]
  );

  const handleCancelEinstellungen = useCallback(
    (values) => {
      props.handleCancelEinstellungen();
    },
    [props.handleCancelEinstellungen]
  );

  const disabledDateTime = () => {
    return {
      disabledHours: () => [],
      disabledMinutes: () => [],
      disabledSeconds: () => [],
    };
  };

  const calculatePause = (value: dayjs.Dayjs | null, type: string) => {
    if (type.includes("pause1von") && value !== null) globalMemory.pause1Von = value;
    if (type.includes("pause2von") && value !== null) globalMemory.pause2Von = value;
    if (type.includes("pause1bis") && value !== null) globalMemory.pause1Bis = value;
    if (type.includes("pause2bis") && value !== null) globalMemory.pause2Bis = value;

    if (
      (globalMemory.pause1Von.isBefore(globalMemory.pause1Bis) ||
        globalMemory.pause1Von.isSame(globalMemory.pause1Bis)) &&
      (globalMemory.pause2Von.isBefore(globalMemory.pause2Bis) || globalMemory.pause2Von.isSame(globalMemory.pause2Bis))
    ) {
      let dayjsCalculate = globalMemory.pause1Bis;
      let hours1 = globalMemory.pause1Von.format("HH");
      let minute1 = globalMemory.pause1Von.format("mm");
      dayjsCalculate = dayjsCalculate.subtract(Number(minute1), "minutes");
      dayjsCalculate = dayjsCalculate.subtract(Number(hours1), "hours");

      let dayjsCalculate2 = globalMemory.pause2Bis;
      let hours2 = globalMemory.pause2Von.format("HH");
      let minute2 = globalMemory.pause2Von.format("mm");
      dayjsCalculate2 = dayjsCalculate2.subtract(Number(minute2), "minutes");
      dayjsCalculate2 = dayjsCalculate2.subtract(Number(hours2), "hours");

      let hours3 = dayjsCalculate2.format("HH");
      let minute3 = dayjsCalculate2.format("mm");
      dayjsCalculate = dayjsCalculate.add(Number(minute3), "minutes");
      dayjsCalculate = dayjsCalculate.add(Number(hours3), "hours");

      form.setFieldValue("pause", dayjsCalculate);
    }
  };

  return (
    <Modal
      title={convertToLocalDate("Arbeitszeit für " + dayjs(detailData.aktTagDetail).format(" dddd") + "s")}
      width={700}
      open={props.displaySettingsModalArbeitszeit}
      okText={"Speichern"}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      footer={null}
    >
      <Divider></Divider>
      <Form
        name="basic"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        style={{ maxWidth: 800 }}
        onFinish={handleOkArbeitszeiten}
        form={form}
        autoComplete="off"
      >
        <Form.Item label="Gültig ab:" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="gueltigAb"
            style={{ display: "inline-block" }}
            initialValue={dayjs(detailData.detailDatumVon)}
          >
            <DatePicker
              picker={"date"}
              value={dayjs(detailData.detailDatumVon)}
              allowClear={false}
              format={"DD.MM.YYYY"}
              locale={locale}
              inputReadOnly
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              className={style.iwMonthPicker}
            />
          </Form.Item>
          {!props.displayDetailBisEndlosArbeitszeit ? (
            <>
              <Form.Item
                label=" bis:"
                name="gueltigBis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs(detailData.detailDatumBis)}
              >
                <DatePicker
                  picker={"date"}
                  allowClear={false}
                  format={"DD.MM.YYYY"}
                  locale={locale}
                  inputReadOnly
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </>
          ) : null}
          <Form.Item
            label=" Unbegrenzt gültig:"
            name="unbegrenzt"
            style={{ display: "inline-block", margin: "0 8px" }}
            initialValue={props.displayDetailBisEndlosArbeitszeit}
          >
            <Switch
              style={{
                caretColor: "blue",
                backgroundColor: props.displayDetailBisEndlosArbeitszeit ? "CornFlowerBlue" : "lightgray",
              }}
              defaultChecked={props.displayDetailBisEndlosArbeitszeit}
              onChange={function (checked: boolean) {
                setDisplayDetailBisEndlosArbeitszeit(checked);
              }}
            ></Switch>
          </Form.Item>
        </Form.Item>
        <Form.Item label="Arbeitszeit:" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="ArbeitszeitAb"
            style={{ display: "inline-block" }}
            initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
          >
            <TimePicker
              defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
              allowClear={false}
              format={"HH:mm"}
              locale={locale}
              showNow={false}
              minuteStep={5}
              disabledTime={disabledDateTime}
              changeOnBlur={true}
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            />
          </Form.Item>
          <Form.Item
            label=" bis"
            name="ArbeitszeitBis"
            style={{ display: "inline-block", margin: "0 8px" }}
            initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
          >
            <TimePicker
              defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
              allowClear={false}
              format={"HH:mm"}
              locale={locale}
              showNow={false}
              minuteStep={5}
              disabledTime={disabledDateTime}
              changeOnBlur={true}
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              className={style.iwMonthPicker}
            />
          </Form.Item>
        </Form.Item>

        <Form.Item label="Pausentyp" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="PauseVariabel"
            style={{ display: "inline-block" }}
            initialValue={props.displayDetailPause}
          >
            <Switch
              style={{
                caretColor: "black",
                color: "black",
                width: "150px",

                backgroundColor: "CornFlowerBlue",
              }}
              defaultChecked={props.displayDetailPause}
              checkedChildren="Fixe Pause"
              unCheckedChildren="Variable Pause"
              onChange={function (checked: boolean) {
                setDisplayDetailPause(checked);
              }}
            ></Switch>
          </Form.Item>
          {/*<Form.Item label="Pause Fix" style={{ display: "inline-block", margin: "0 8px" }}></Form.Item>*/}
        </Form.Item>
        <Form.Item
          label="Pause:"
          name="pause"
          style={{ marginBottom: "15px" }}
          initialValue={dayjs("2000-01-01 " + detailData.detailPause)}
        >
          <TimePicker
            defaultValue={dayjs("2000-01-01 " + detailData.detailPause)}
            allowClear={false}
            format={"HH:mm"}
            locale={locale}
            showNow={false}
            minuteStep={5}
            changeOnBlur={true}
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            className={style.iwMonthPicker}
          />
        </Form.Item>
        {props.displayDetailPause ? (
          <>
            <Form.Item label="Pause 1:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause1Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause1Von)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause1Von)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause1von");
                  }}
                  disabledTime={disabledDateTime}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause1Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause1Bis)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause1Bis)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  onChange={function (value) {
                    calculatePause(value, "pause1bis");
                  }}
                  showNow={false}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </Form.Item>
            <Form.Item label="Pause 2:" style={{ marginBottom: "0px" }}>
              <Form.Item
                label=""
                name="pause2Von"
                style={{ display: "inline-block" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause2Von)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause2Von)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause2von");
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
              <Form.Item
                label="bis"
                name="pause2Bis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs("2000-01-01" + detailData.detailPause2Bis)}
              >
                <TimePicker
                  defaultValue={dayjs("2000-01-01" + detailData.detailPause2Bis)}
                  allowClear={true}
                  format={"HH:mm"}
                  locale={locale}
                  inputReadOnly
                  changeOnBlur={true}
                  minuteStep={5}
                  disabledTime={disabledDateTime}
                  showNow={false}
                  onChange={function (value) {
                    calculatePause(value, "pause2bis");
                  }}
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </Form.Item>{" "}
          </>
        ) : null}
        <Form.Item
          label="Turnus:"
          name="turnus"
          style={{ marginBottom: "15px" }}
          initialValue={detailData.detailTurnusTyp}
        >
          <Select
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            options={[
              { value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jede Woche" },
              { value: APIEnumTurnusTyp.ZWEIWOECHENTLICH, label: "Jede zweite Woche" },
            ]}
          />
        </Form.Item>
        <Form.Item
          label="Bemerkung:"
          name="bemerkung"
          style={{ marginBottom: "15px" }}
          initialValue={detailData.detailBemerkung}
        >
          <Input defaultValue={"" + detailData.detailBemerkung} style={{ backgroundColor: "#e1f0fc" }}></Input>
        </Form.Item>
        <Divider></Divider>
        {!props.displayNewEvent ? (
          <Form.Item label="Gültigkeit:" style={{ marginBottom: "0px" }}>
            <Form.Item
              label=""
              name="tagOderTurnus"
              style={{ display: "inline-block" }}
              initialValue={props.displayDetailEditZeitKontext}
            >
              <Switch
                style={{
                  caretColor: "black",
                  color: "black",
                  width: "150px",
                  backgroundColor: "CornFlowerBlue",
                }}
                defaultChecked={props.displayDetailEditZeitKontext}
                checkedChildren="Ganze Serie"
                unCheckedChildren="Nur dieser Tag"
                onChange={function (checked: boolean) {
                  setDisplayDetailEditZeitKontext(checked);
                }}
              ></Switch>
            </Form.Item>
          </Form.Item>
        ) : null}
        <Form.Item wrapperCol={{ offset: 0, span: 30 }} style={{ marginBottom: "0px" }}>
          {!props.displayNewEvent ? (
            <>
              <Button
                type="primary"
                onClick={props.handleOkDelete}
                style={{
                  backgroundColor: "orangered",
                  color: "white",
                  marginRight: "370px",
                }}
              >
                Löschen
              </Button>
              <Button
                type="primary"
                onClick={handleCancelEinstellungen}
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#4096ff",

                  marginRight: "5px",
                }}
              >
                Abbrechen
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={handleCancelEinstellungen}
              style={{
                backgroundColor: "#f5f5f5",
                color: "#4096ff",
                marginLeft: "453px",
                marginRight: "5px",
              }}
            >
              Abbrechen
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IWArbeitszeitenmodal;
