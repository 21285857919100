import dayjs from "dayjs";

export const APPIDENT = "CONNECT";

export const API_PORT = "443"; // dev: 8443; prod: 443
export const API_SERVER = `https://www.e-cut-api1.de:${API_PORT}`;
// export const API_SERVER = `http://localhost:8080`; // Falls API lokal auf dem Rechner

export const DEFAULT_DATEPICKER_START_MOMENT = dayjs().startOf("month");
export const DEFAULT_DATEPICKER_END_MOMENT = dayjs().endOf("month");

export const SERVER_POLL_INTERVALL = 10000; // 10 Sekunden
