import React, { useCallback, useState } from "react";
import { Button, DatePicker, Divider, Form, Input, Modal, Select, Switch, TimePicker } from "antd";
import { upperCaseStringFirstLetter } from "../../../utils/functions";
import { convertToLocalDate } from "../../../utils/functionsDatum";
import dayjs from "dayjs";
import { APIEnumTurnusTyp, APIEnumZeitTyp } from "../../../types/apiTypes";
import locale from "antd/es/date-picker/locale/de_DE";
import style from "../Datev/Datev.module.css";

const IWAbwesenheitsmodal = (props: any) => {
  let [form] = Form.useForm(); //TODO CK

  const isDatumBisVisible = () => {
    if (props.displayDetailBisEndlosAbwesenheit) {
      if (props.isUrlaubOderKrank) return true;
      else return false;
    } else {
      return true;
    }
    return false;
  };

  const setDisplayFirstOpen = useCallback(() => {
    props.setDisplayFirstOpen(false);
  }, [props.setDisplayFirstOpen]);

  const setisUrlaubOderKrankTrue = useCallback(() => {
    props.setisUrlaubOderKrank(true);
  }, [props.setisUrlaubOderKrank]);

  const setisUrlaubOderKrankFalse = useCallback(() => {
    props.setisUrlaubOderKrank(false);
  }, [props.setisUrlaubOderKrank]);

  if (props.displayFirstOpen) {
    form.resetFields();
    if (
      (props.detailData.detailAbwesenheitsTyp === APIEnumZeitTyp.KRANK ||
        props.detailData.detailAbwesenheitsTyp === APIEnumZeitTyp.URLAUB) &&
      !props.isUrlaubOderKrank
    ) {
      setisUrlaubOderKrankTrue();
    }
    if (
      (props.detailData.detailAbwesenheitsTyp !== APIEnumZeitTyp.KRANK ||
        props.detailData.detailAbwesenheitsTyp !== APIEnumZeitTyp.URLAUB) &&
      props.isUrlaubOderKrank
    ) {
      setisUrlaubOderKrankFalse();
    }

    setDisplayFirstOpen();
  }

  let detailData = props.detailData;

  if (props.displayNewEvent) {
    props.setDisplayDetailEditZeitKontext(true);
  }

  const handleKontext = useCallback(
    (value) => {
      props.setDisplayDetailEditZeitKontext(value);
    },
    [props.setDisplayDetailEditZeitKontext]
  );

  const setDisplayDetailBisEndlosAbwesenheit = useCallback(
    (value) => {
      props.setDisplayDetailBisEndlosAbwesenheit(value);
    },
    [props.setDisplayDetailBisEndlosAbwesenheit]
  );

  const handleOkAbwesenheit = useCallback(
    (values) => {
      props.handleOkAbwesenheit(values, false);
    },
    [props.handleOkAbwesenheit]
  );

  return (
    <Modal
      title={convertToLocalDate("Abwesenheitszeit für " + dayjs(detailData.aktTagDetail).format(" dddd") + "s")}
      width={700}
      open={props.displaySettingsModalAbwesenheit}
      okText={"Speichern"}
      maskClosable={false}
      footer={false}
      closable={false}
      destroyOnClose={true}
    >
      <Divider></Divider>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 21 }}
        style={{ maxWidth: 800 }}
        onFinish={handleOkAbwesenheit}
        autoComplete="off"
      >
        <Form.Item
          label="Typ :"
          name="detailAbwesenheitsTyp"
          style={{ marginBottom: "15px" }}
          initialValue={detailData.detailAbwesenheitsTyp}
        >
          <Select
            style={{ width: "150px", backgroundColor: "#e1f0fc" }}
            options={[
              {
                value: APIEnumZeitTyp.URLAUB,
                label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.URLAUB),
              },
              { value: APIEnumZeitTyp.KRANK, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.KRANK) },
              /**{ value: APIEnumZeitTyp.FEIERTAG, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.FEIERTAG) },**/
              { value: APIEnumZeitTyp.SCHULE, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.SCHULE) },
              /**{ value: APIEnumZeitTyp.RUHETAG, label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.RUHETAG) }, **/
              /**{
                value: APIEnumZeitTyp.WOCHENENDE,
                label: "" + upperCaseStringFirstLetter(APIEnumZeitTyp.WOCHENENDE),
              },**/
            ]}
            disabled={props.displayAbwesenheitSelectDisabled}
            onChange={function (value) {
              props.handleChangeAbwesenheitTyp(value);
              if (value === APIEnumZeitTyp.KRANK || value === APIEnumZeitTyp.URLAUB) setisUrlaubOderKrankTrue();
              else setisUrlaubOderKrankFalse();
            }}
          />
        </Form.Item>
        <Form.Item label="Gültig ab:" style={{ marginBottom: "0px" }}>
          <Form.Item
            label=""
            name="gueltigAb"
            style={{ display: "inline-block" }}
            initialValue={dayjs(detailData.detailDatumVon)}
          >
            <DatePicker
              picker={"date"}
              value={dayjs(detailData.detailDatumVon)}
              allowClear={false}
              format={"DD.MM.YYYY"}
              locale={locale}
              inputReadOnly
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              className={style.iwMonthPicker}
            />
          </Form.Item>
          {isDatumBisVisible() ? (
            <>
              <Form.Item
                label=" bis:"
                name="gueltigBis"
                style={{ display: "inline-block", margin: "0 8px" }}
                initialValue={dayjs(detailData.detailDatumBis)}
              >
                <DatePicker
                  picker={"date"}
                  allowClear={false}
                  format={"DD.MM.YYYY"}
                  locale={locale}
                  inputReadOnly
                  style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                  className={style.iwMonthPicker}
                />
              </Form.Item>
            </>
          ) : null}
          {!props.isUrlaubOderKrank ? (
            <Form.Item
              label=" Unbegrenzt gültig:"
              name="unbegrenzt"
              style={{ display: "inline-block", margin: "0 8px" }}
              initialValue={props.displayDetailBisEndlosAbwesenheit}
            >
              <Switch
                style={{
                  caretColor: "blue",
                  backgroundColor: props.displayDetailBisEndlosAbwesenheit ? "CornFlowerBlue" : "lightgray",
                }}
                defaultChecked={props.displayDetailBisEndlosAbwesenheit}
                onChange={function (checked: boolean) {
                  setDisplayDetailBisEndlosAbwesenheit(checked);
                }}
              ></Switch>
            </Form.Item>
          ) : null}
        </Form.Item>
        {props.displayAbwesenheitSelectSchule ? (
          <Form.Item label="Uhrzeit von :" style={{ marginBottom: "0px" }}>
            <Form.Item
              label=""
              name="ArbeitszeitAb"
              style={{ display: "inline-block" }}
              initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
            >
              <TimePicker
                defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitVon)}
                allowClear={false}
                format={"HH:mm"}
                locale={locale}
                showNow={false}
                minuteStep={5}
                changeOnBlur={true}
                style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              />
            </Form.Item>
            <Form.Item
              label=" bis"
              name="ArbeitszeitBis"
              style={{ display: "inline-block", margin: "0 8px" }}
              initialValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
            >
              <TimePicker
                defaultValue={dayjs("2000-01-01" + detailData.detailUhrzeitBis)}
                allowClear={false}
                format={"HH:mm"}
                locale={locale}
                showNow={false}
                minuteStep={5}
                changeOnBlur={true}
                style={{ width: "150px", backgroundColor: "#e1f0fc" }}
                className={style.iwMonthPicker}
              />
            </Form.Item>
          </Form.Item>
        ) : null}
        {true ? (
          <Form.Item
            label="Turnus:"
            name="turnus"
            style={{ marginBottom: "15px" }}
            initialValue={detailData.detailTurnusTyp}
          >
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              options={[
                { value: APIEnumTurnusTyp.TAEGLICH, label: "Täglich" },
                { value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jede Woche" },
                { value: APIEnumTurnusTyp.ZWEIWOECHENTLICH, label: "Jede zweite Woche" },
                { value: APIEnumTurnusTyp.MONATLICH, label: "Jeden Monat" },
                { value: APIEnumTurnusTyp.JAEHRLICH, label: "Jedes Jahr" },
              ]}
            />
          </Form.Item>
        ) : null}
        {/*
        {props.displayAbwesenheitSelect.includes(APIEnumZeitTyp.KRANK) ||
        props.displayAbwesenheitSelect.includes(APIEnumZeitTyp.URLAUB) ? (
          <Form.Item
            label="Turnus:"
            name="turnus"
            style={{ marginBottom: "15px" }}
            initialValue={APIEnumTurnusTyp.TAEGLICH}
          >
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              disabled={false}
              options={[{ value: APIEnumTurnusTyp.TAEGLICH, label: "Täglich" }]}
            />
          </Form.Item>
        ) : null}

        {props.displayAbwesenheitSelect.includes(APIEnumZeitTyp.FEIERTAG) ? (
          <Form.Item
            label="Turnus:"
            name="turnus"
            style={{ marginBottom: "15px" }}
            initialValue={APIEnumTurnusTyp.JAEHRLICH}
          >
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              disabled={false}
              options={[{ value: APIEnumTurnusTyp.JAEHRLICH, label: "Jedes Jahr" }]}
            />
          </Form.Item>
        ) : null}

        {props.displayAbwesenheitSelect.includes(APIEnumZeitTyp.WOCHENENDE) ? (
          <Form.Item
            label="Turnus:"
            name="turnus"
            style={{ marginBottom: "15px" }}
            initialValue={APIEnumTurnusTyp.JEDEWOCHE}
          >
            <Select
              style={{ width: "150px", backgroundColor: "#e1f0fc" }}
              disabled={false}
              options={[{ value: APIEnumTurnusTyp.JEDEWOCHE, label: "Jedes Woche" }]}
            />
          </Form.Item>
        ) : null}*/}

        <Form.Item
          label="Bemerkung:"
          name="bemerkung"
          style={{ marginBottom: "25px" }}
          initialValue={detailData.detailBemerkung}
        >
          <Input defaultValue={"" + detailData.detailBemerkung} style={{ backgroundColor: "#e1f0fc" }}></Input>
        </Form.Item>
        <Divider></Divider>
        {!props.displayNewEvent ? (
          <Form.Item label="Gültigkeit:" style={{ marginBottom: "0px" }}>
            <Form.Item
              label=""
              name="tagOderTurnus"
              style={{ display: "inline-block" }}
              initialValue={props.displayDetailEditZeitKontext}
            >
              <Switch
                style={{
                  caretColor: "black",
                  color: "black",
                  width: "150px",
                  backgroundColor: "CornFlowerBlue",
                }}
                defaultChecked={props.displayDetailEditZeitKontext}
                checkedChildren="Ganze Serie"
                unCheckedChildren="Nur dieser Tag"
                onChange={function (checked: boolean) {
                  handleKontext(checked);
                }}
              ></Switch>
            </Form.Item>
          </Form.Item>
        ) : null}
        <Form.Item wrapperCol={{ offset: 0, span: 30 }} style={{ marginBottom: "0px" }}>
          {!props.displayNewEvent ? (
            <>
              <Button
                type="primary"
                onClick={props.handleOkDelete}
                style={{
                  backgroundColor: "orangered",
                  color: "white",
                  marginRight: "370px",
                }}
              >
                Löschen
              </Button>
              <Button
                type="primary"
                onClick={props.handleCancelEinstellungen}
                style={{
                  backgroundColor: "#f5f5f5",
                  color: "#4096ff",

                  marginRight: "5px",
                }}
              >
                Abbrechen
              </Button>
            </>
          ) : (
            <Button
              type="primary"
              onClick={props.handleCancelEinstellungen}
              style={{
                backgroundColor: "#f5f5f5",
                color: "#4096ff",
                marginLeft: "453px",
                marginRight: "5px",
              }}
            >
              Abbrechen
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            style={{
              backgroundColor: "#4096ff",
              color: "#f5f5f5",
            }}
          >
            Speichern
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default IWAbwesenheitsmodal;
