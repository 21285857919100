import { useQuery } from "react-query";
import { fetchData, getWertFromlocalStorage } from "../utils/functions";
import { APIStammdaten } from "../types/apiTypes";
import { APPIDENT } from "../constants";
const pkg = require("../../package.json");

interface QueryParams {
  userToken: string;
}

const getQuery = ({ userToken }: QueryParams) => ({
  query: `query {
      getStammdaten(
        appIdent: ${APPIDENT},    
        version: "${pkg.version}",    
        userToken: "${userToken}") {
        header {
          success,
          errorCode,
          errorText
        },
        data {
          buchhaltungskonten {
          id,
          kontonr,
          text,
          steuerschluessel,
          kontotyp,
          del
          },
        statistiken {
          id
          titel
          showDatepicker
          typeDatepicker
          showPDFButton
          showPrintButton
          showExportButton
          showSelectBoxLine
          showSelectBoxColumn
          labelSelectBoxLine
          labelSelectBoxColumn
          chartType
          chartLineOrColumn
          chartDataIndex
          chartDataLabel
          chartIgnoreDataIndex
          showAlleFilialenMenu
          showVerbuendeMenu
          pos
          },
        syncTabellen,
        mitarbeiterJeFiliale {
          filialToken
          mitarbeiter {
            id
            name
            del
            }
          }
        }
      }
  }
  `,
});

export const useStammdaten = (queryOptions = {}) => {
  if (getWertFromlocalStorage("loginInfo", "userToken") === "") queryOptions = { enabled: false };

  const queryParams: QueryParams = {
    userToken: getWertFromlocalStorage("loginInfo", "userToken"),
  };

  let queryObject = getQuery(queryParams);

  let { isLoading, error, data } = useQuery(
    ["stammdaten", queryObject.query],
    () => fetchData(queryObject),
    queryOptions
  );

  let stammdaten: APIStammdaten = data?.data?.data?.getStammdaten?.data ?? {};

  // TODO Vorübergehend alle Konten zusammenfassen die keine Belege sind
  // Ist im Moment in localStorage gespeichert und nicht in Stammdaten
  /*
  stammdaten.buchhaltungZusammenfassKonten = [];

  if (stammdaten.buchhaltungskonten) {
    stammdaten.buchhaltungskonten.map((kontoO: any) => {
      if (kontoO.kontotyp !== "BELEG" && kontoO.kontotyp !== "BANKEINZAHLUNG") {
        stammdaten.buchhaltungZusammenfassKonten.push(kontoO.id);
      }
    });
  }
   */
  return { isLoading, error, stammdaten };
};
