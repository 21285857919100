import { useQuery } from "react-query";
import { fetchData } from "../utils/functions";
import { APILoginInfo } from "../types/apiTypes";
import { APPIDENT } from "../constants";
const pkg = require('../../package.json')

interface QueryParams {
  benutzername: string;
  passwort: string;
}

const getQuery = ({ benutzername, passwort }: QueryParams) => ({
  query: `query {
        login (
          appIdent: ${APPIDENT},
          version: "${pkg.version}",    
          benutzername: "${benutzername}",
          passwort: "${passwort}",
        ) 
        {
          header {
            success,
            errorCode,
            errorText
        },
        data {
            benutzername,
            userToken,
            rolle,
            name,
            vorname,
            email
        }
        }
      }`,
});

export const useLogin = (queryParams: QueryParams, queryOptions = {}) => {
  let queryObject = getQuery(queryParams);

  let { isLoading, error, data } = useQuery(["login", queryObject.query], () => fetchData(queryObject), queryOptions);

  const loginInfo: APILoginInfo = data?.data?.data?.login?.data ?? {};

  // TODO: Prüfung auf Fehlermeldung falscher User/PW
  if (loginInfo.benutzername) {
    localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
  }

  return { isLoading, error, loginInfo };
};
