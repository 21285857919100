// Types für API Endpunkte (Endpunkte alphabetisch sortiert)
// Datei ist identisch zwischen API und Frontend

// enum für alle Endpunkte
export enum APIVarType {
  APIVARTYPE_NUMBER = "APIVARTYPE_NUMBER",
  APIVARTYPE_STRING = "APIVARTYPE_STRING",
  APIVARTYPE_DATE = "APIVARTYPE_DATE",
}

export enum APIHorAlignment {
  APIHORALIGNMENT_LEFT = "APIHORALIGNMENT_LEFT",
  APIHORALIGNMENT_RIGHT = "APIHORALIGNMENT_RIGHT",
  APIHORALIGNMENT_CENTER = "APIHORALIGNMENT_CENTER",
}

export enum APITabSort {
  APITABSORT_NONE = "APITABSORT_NONE",
  APITABSORT_ASC = "APITABSORT_ASC",
  APITABSORT_DESC = "APITABSORT_DESC",
}

export enum APIFixed {
  APIFIXED_NONE = "APIFIXED_NONE",
  APIFIXED_LEFT = "APIFIXED_LEFT",
  APIFIXED_RIGHT = "APIFIXED_RIGHT",
}

export enum APIEnumDatepicker {
  NONE = "NONE",
  DAY = "DAY",
  DAY_DAY = "DAY_DAY",
  MONTH = "MONTH",
  MONTH_MONTH = "MONTH_MONTH",
  QUARTER = "QUARTER",
  QUARTER_QUARTER = "QUARTER_QUARTER",
  YEAR = "YEAR",
  YEAR_YEAR = "YEAR_YEAR",
  WEEK = "WEEK",
  WEEK_WEEK = "WEEK_WEEK",
}

// Types für alle Endpunkte
export type APIIDName = {
  id: string;
  name: string;
};

export type APIHeader = {
  success: boolean;
  errorCode: number;
  errorText: string;
};

export type APITabHeader = {
  text: string;
  sortierbar: boolean;
  suchbar: boolean;
  stdSortierung: APITabSort;
  typ: APIVarType;
  ausrichtung: APIHorAlignment;
  breiteProzent: number;
  sichtbar: boolean;
  statisch?: APIFixed;
};

export type APITabHeaderExtended = {
  text: string;
  sortierbar: boolean;
  suchbar: boolean;
  stdSortierung: APITabSort;
  typ: APIVarType;
  ausrichtung: APIHorAlignment;
  breiteProzent: number;
  sichtbar: boolean;
  statisch: APIFixed;
  mitZusatzinformationen: boolean;
};

export type APITabZeile = {
  spalten: string[];
};

export type APITabZeileExtended = {
  spalten: string[];
  spaltenProps: string[];
};

export type APIErfolgRueckmeldung = {
  erfolgreich: boolean;
  dbid: number;
  ueberschrift: string;
  infotext: string;
};

export enum APIEnumZeitKontext {
  EINZEL = "EINZEL",
  SERIE = "SERIE",
}

export enum APIEnumTurnusTyp {
  TAEGLICH = "TAEGLICH",
  JEDEWOCHE = "JEDEWOCHE",
  GERADEWOCHE = "GERADEWOCHE",
  UNGERADEWOCHE = "UNGERADEWOCHE",
  ZWEIWOECHENTLICH = "ZWEIWOECHENTLICH",
  MONATLICH = "MONATLICH",
  JAEHRLICH = "JAEHRLICH",
}

export enum APIEnumZeitTyp {
  ARBEITSZEIT = "ARBEITSZEIT", // Prio 0
  URLAUB = "URLAUB", // Prio 3
  KRANK = "KRANK", // Prio 4
  SCHULE = "SCHULE", // Prio 2 (wenn WOCHENENDE oder RUHETAG dabei, dann höher als WOCHENENDE oder RUHETAG -> Prio 7)
  SONSTIGES = "SONSTIGES", // Prio 1
  FEIERTAG = "FEIERTAG", // Prio 8
  WOCHENENDE = "WOCHENENDE", // Prio 6
  RUHETAG = "RUHETAG", // Prio 5
}

// ====================================================================================================================

// Endpunkt getArbeitszeiten
export type APIArbeitszeiten = {
  tabHeader: APITabHeaderExtended[];
  tabZeilen: APITabZeileExtended[];
};

// Endpunkt Login
export enum APIEnumRolle {
  APIDB_ENUM_ADMIN = "ADMIN",
  APIDB_ENUM_GESCHAEFTSFUEHRUNG = "GESCHAEFTSFUEHRUNG",
  APIDB_ENUM_FILIALLEITUNG = "FILIALLEITUNG",
  APIDB_ENUM_MITARBEITER = "MITARBEITER",
}

export type APILoginInfo = {
  benutzername: string;
  userToken: string;
  rolle: APIEnumRolle;
  name: string;
  vorname: string;
  email: string;
};

// Endpunkt getBuchungsstapel
export type APIBuchungszeile = {
  id: string;
  datum: string;
  belegNr: string;
  datevNr: string;
  text: string;
  betrag: string;
  sollKonto: number;
  habenKonto: number;
  kst: string;
  mwstSatz: number;
};

// Endpunkt Filialen
export type APIFiliale = {
  filialToken: string;
  name: string;
  alias: string;
  connectToken: string;
};

export type APIVerbund = {
  verbundToken: string;
  alias: string;
  filialen: string[];
};

export type APIFilialenVerbuende = {
  filialen: APIFiliale[];
  verbuende: APIVerbund[];
};

// Endpunkt für Stammdaten
export enum APIEnumChart {
  NONE = "NONE",
  BARCHART = "BARCHART",
  LINECHART = "LINECHART",
  PIECHART = "PIECHART",
  RADIALCHART = "RADIALCHART",
}

export enum APIEnumLineOrColumn {
  LINE = "LINE",
  COLUMN = "COLUMN",
}

export type APISDBuchhaltungskonto = {
  id: number;
  kontonr: number;
  text: string;
  steuerschluessel: number;
  kontotyp: string; // "" | "BELEG" | "BANKEINZAHLUNG"
  del: boolean;
};

export type APISDStatistik = {
  id: number;
  titel: string;
  showDatepicker: boolean;
  typeDatepicker: APIEnumDatepicker;
  showPDFButton: boolean;
  showPrintButton: boolean;
  showExportButton: boolean;
  showSelectBox: number; // Aus Abwärtskompatibilität Connect V1.1 und früher
  showSelectBoxLine: number;
  showSelectBoxColumn: number;
  labelSelectBoxLine: string;
  labelSelectBoxColumn: string;
  chartType: APIEnumChart;
  chartLineOrColumn: APIEnumLineOrColumn;
  chartDataIndex: number;
  chartDataLabel: number;
  chartIgnoreDataIndex: number[];
  showVerbuendeMenu: boolean;
  showAlleFilialenMenu: boolean;
  pos: number;
};

export type APISDMitarbeiter = {
  id: number;
  name: string;
  del: boolean;
};

export type APISDMitarbeiterJeFiliale = {
  filialToken: string;
  mitarbeiter: APISDMitarbeiter[];
};

export type APIStammdaten = {
  buchhaltungskonten: APISDBuchhaltungskonto[];
  statistiken: APISDStatistik[];
  syncTabellen: string[];
  mitarbeiterJeFiliale: APISDMitarbeiterJeFiliale[];
};

// Endpunkt getStatistik
export type APIStatistik = {
  textHeader: string;
  tabHeader: APITabHeader[];
  tabZeilen: APITabZeile[];
};

// Endpunkt CreateUser
export type APICreateUser = {
  userTokenNeu: string;
};

// Endpunkt CreateOrganisation
export type APICreateOrganisation = {
  userToken: string;
};

// Endpunkt getSollArbeitszeiten
export type APIGetSollArbeitszeiten = {
  maid: number;
  sollAZeitFilialen: APISollAZeitFiliale[];
  sollAZeitMATage: APISollAZeitMATag[];
  sollAZeitSumme: APISollAZeitSumme;
};

export type APISollAZeitFiliale = {
  filialToken: string;
  readOnly: boolean;
  sollAZeitFilialTage: APISollAZeitFilialTag[];
  sollAZeitSumme: APISollAZeitSumme;
};

export type APISollAZeitFilialTag = {
  datum: string;
  arbeitszeit: APISollAZeitFilialTagArbeitszeit[];
  abwesenheit: APISollAZeitFilialTagAbwesenheit[];
  anzeigeZeitTyp: APIEnumZeitTyp;
  anzeigeZeitTypAktiv: boolean;
};

export type APISollAZeitMATag = {
  datum: string;
  sollAZeitSumme: APISollAZeitSumme;
  anzeigeZeitTyp: APIEnumZeitTyp;
  anzeigeZeitTypAktiv: boolean;
};

export type APISollAZeitFilialTagArbeitszeit = {
  id: number;
  datumVon: string;
  datumBis: string;
  arbeitszeit: string;
  uhrzeitVon: string;
  uhrzeitBis: string;
  pause: string;
  pauseFix: boolean;
  pause1Von: string;
  pause1Bis: string;
  pause2Von: string;
  pause2Bis: string;
  turnusTyp: APIEnumTurnusTyp;
  bemerkung: string;
};

export type APISollAZeitFilialTagAbwesenheit = {
  id: number;
  abwesendTyp: APIEnumZeitTyp;
  abwesendTypAktiv: boolean;
  datumVon: string;
  datumBis: string;
  uhrzeitVon: string;
  uhrzeitBis: string;
  turnusTyp: APIEnumTurnusTyp;
  halberTag: boolean;
  rest: string;
  bemerkung: string;
};

export type APISollAZeitSumme = {
  arbeitszeit: string;
  urlaub: string;
  krank: string;
  schule: string;
  sonstiges: string;
  feiertag: string;
  wochenende: string;
  ruhetag: string;
};

// Endpunkt getUrlaubsanspruch
export type APIGetUrlaubsanspruch = {
  jahr: number;
  maid: number;
  urlaubsanspruch: number;
};
