import React, { createElement, useState } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Button, Drawer, Layout, Menu, Row } from "antd";
import {
  EuroCircleOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import style from "./NavLeft.module.css";
import { useFilialen, useFilialenPlaner } from "../../../hooks/useFilialen";
import { useVerbuende } from "../../../hooks/useVerbuende";
import { APIFiliale } from "../../../types/apiTypes";
import { isCompanyAdministrator, isSalonManagerAndAbove } from "../../../utils/functionsSecurity";
import { useStammdaten } from "../../../hooks/useStammdaten";
import { globalMemory } from "../../../globals";

const { SubMenu } = Menu;
const { Sider } = Layout;

const NavLeft = (props: any) => {
  const { pathname } = useLocation();

  const [openNavLeft, setOpenNavLeft] = useState(true);
  const [navLeftSize, setNavLeftSize] = useState(375);

  const isDatevPage = useRouteMatch("/datev") !== null;
  const isStatistikPage = useRouteMatch("/statistik") !== null;
  const isArbeitszeitenPage = useRouteMatch("/arbeitszeiten") !== null;
  const isSollArbeitszeitenPage = useRouteMatch("/sollarbeitszeiten") !== null;
  const isPlaner = useRouteMatch("/planer") !== null;

  const { stammdaten } = useStammdaten();
  const syncMitarbeiter = stammdaten.syncTabellen ? stammdaten.syncTabellen.includes("ma_info") : false;
  const syncKonten = stammdaten.syncTabellen ? stammdaten.syncTabellen.includes("bh_konten") : false;

  let statistikIDA: number = 100;
  if (pathname.split("/")[2] !== undefined && pathname.split("/")[2] !== null && pathname.split("/")[2] !== "") {
    statistikIDA = parseInt(pathname.split("/")[2]);
  }

  const { filialen } = useFilialen({
    enabled: isDatevPage || isStatistikPage || isArbeitszeitenPage || isSollArbeitszeitenPage || isPlaner,
  });

  const { filialenPlaner } = useFilialenPlaner({
    enabled: isDatevPage || isStatistikPage || isArbeitszeitenPage || isSollArbeitszeitenPage || isPlaner,
  });

  const { verbuende } = useVerbuende({
    enabled: isDatevPage || isStatistikPage || isArbeitszeitenPage || isSollArbeitszeitenPage,
  });

  const renderFilialeDatev = (filiale: APIFiliale) => (
    <Menu.Item key={filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/datev/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeArbeitszeiten = (filiale: APIFiliale) => (
    <Menu.Item key={filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/arbeitszeiten/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeSollArbeitszeiten = (filiale: APIFiliale) => (
    <Menu.Item key={filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/sollarbeitszeiten/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialeStatistik = (filiale: APIFiliale) => (
    <Menu.Item key={"s" + statistikIDA + filiale.filialToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/statistik/${statistikIDA}/filiale/${filiale.filialToken}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const renderFilialePlaner = (filiale: APIFiliale) => (
    <Menu.Item key={filiale.connectToken} style={{ borderRadius: "0px", textAlign: "left" }}>
      <Link to={`/planer/${encodeURIComponent(filiale.connectToken)}`}>{filiale.alias}</Link>
    </Menu.Item>
  );

  const getBooleanForMenu = (isAlle: boolean, isVerbund: boolean, statistikID: number) => {
    for (let i = 0; i < stammdaten.statistiken.length; i++)
      if (stammdaten.statistiken[i].id === statistikID) {
        console.log(stammdaten.statistiken[i].id);
        if (stammdaten.statistiken[i].showAlleFilialenMenu && isAlle) {
          return true;
        } else if (stammdaten.statistiken[i].showVerbuendeMenu && isVerbund) {
          return true;
        }
      }
    return false;
  };

  const handleMenuSwitch = () => {
    if (navLeftSize !== 0) {
      setNavLeftSize(0);
      props.setIsNavLeftVisible(false);
    } else {
      setNavLeftSize(375);
      props.setIsNavLeftVisible(true);
    }
    //globalMemory.calObj?.getApi().updateSize();
  };

  return (
    <>
      <MenuOutlined
        size={60}
        style={{ fontSize: "22px", color: "grey", position: "absolute", left: 15, top: 25 }}
        onClick={handleMenuSwitch}
      />

      <Sider
        width={navLeftSize}
        className="site-layout-background"
        style={{
          textAlign: "center",
          fontSize: "x-large",
          color: "white",
          overflowY: "scroll",
        }}
      >
        <Menu mode="inline" theme="dark" style={{ width: "100%", height: "100%", borderRadius: "0px" }}>
          {pathname === "/home" && []}

          {isDatevPage ? (
            <>
              {syncKonten ? (
                <>
                  <Menu.Item key="sub0" style={{ borderRadius: "0px", textAlign: "left" }}>
                    <Link to="/datev/all">Alle Filialen</Link>
                  </Menu.Item>
                  <SubMenu key="sub1" title="Verbund" style={{ borderRadius: "0px", textAlign: "left" }}>
                    {verbuende && filialen
                      ? verbuende.map((verbund) => (
                          <SubMenu
                            key={verbund.verbundToken}
                            title={verbund.alias}
                            className={style.MenuEintrag}
                            style={{ borderRadius: "0px", textAlign: "left" }}
                          >
                            <Menu.Item key={verbund.verbundToken} style={{ borderRadius: "0px", textAlign: "left" }}>
                              <Link to={`/datev/verbund/${verbund.verbundToken}`}>{verbund.alias} GESAMT</Link>
                            </Menu.Item>
                            {verbund.filialen.map((filialKey) => {
                              const filiale = filialen.find((f) => f.filialToken === filialKey);
                              return filiale ? renderFilialeDatev(filiale) : null;
                            })}
                          </SubMenu>
                        ))
                      : null}
                  </SubMenu>
                </>
              ) : null}
              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialen ? filialen.map(renderFilialeDatev) : null}
              </SubMenu>
            </>
          ) : null}

          {isStatistikPage ? (
            <>
              {isCompanyAdministrator() && getBooleanForMenu(true, false, statistikIDA) ? (
                <Menu.Item key="sub0" style={{ borderRadius: "0px", textAlign: "left" }}>
                  <Link to={`/statistik/${statistikIDA}/all`}>Alle Filialen</Link>
                </Menu.Item>
              ) : null}
              {isCompanyAdministrator() && getBooleanForMenu(false, true, statistikIDA) ? (
                <SubMenu key="sub1" title="Verbund" style={{ borderRadius: "0px", textAlign: "left" }}>
                  {verbuende && filialen
                    ? verbuende.map((verbund) => (
                        <SubMenu
                          key={verbund.verbundToken}
                          title={verbund.alias}
                          className={style.MenuEintrag}
                          style={{ borderRadius: "0px", textAlign: "left" }}
                        >
                          <Menu.Item key={verbund.verbundToken} style={{ borderRadius: "0px", textAlign: "left" }}>
                            <Link to={`/statistik/${statistikIDA}/verbund/${verbund.verbundToken}`}>
                              {verbund.alias} GESAMT
                            </Link>
                          </Menu.Item>
                          {verbund.filialen.map((filialKey) => {
                            const filiale = filialen.find((f) => f.filialToken === filialKey);
                            return filiale ? renderFilialeStatistik(filiale) : null;
                          })}
                        </SubMenu>
                      ))
                    : null}
                </SubMenu>
              ) : null}
              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialen ? filialen.map(renderFilialeStatistik) : null}
              </SubMenu>
            </>
          ) : null}

          {isArbeitszeitenPage ? (
            <>
              {syncMitarbeiter && isCompanyAdministrator() ? (
                <Menu.Item key="sub0" style={{ borderRadius: "0px", textAlign: "left" }}>
                  <Link to={`/arbeitszeiten/all`}>Alle Filialen</Link>
                </Menu.Item>
              ) : null}
              {syncMitarbeiter && isSalonManagerAndAbove() ? (
                <SubMenu key="sub1" title="Verbund" style={{ borderRadius: "0px", textAlign: "left" }}>
                  {verbuende && filialen
                    ? verbuende.map((verbund) => (
                        <SubMenu key={verbund.verbundToken} title={verbund.alias} className={style.MenuEintrag}>
                          <Menu.Item key={verbund.verbundToken} style={{ borderRadius: "0px", textAlign: "left" }}>
                            <Link to={`/arbeitszeiten/verbund/${verbund.verbundToken}`}>{verbund.alias} GESAMT</Link>
                          </Menu.Item>
                          {verbund.filialen.map((filialKey) => {
                            const filiale = filialen.find((f) => f.filialToken === filialKey);
                            return filiale ? renderFilialeArbeitszeiten(filiale) : null;
                          })}
                        </SubMenu>
                      ))
                    : null}
                </SubMenu>
              ) : null}

              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialen ? filialen.map(renderFilialeArbeitszeiten) : null}
              </SubMenu>
            </>
          ) : null}

          {isSollArbeitszeitenPage ? (
            <>
              {syncMitarbeiter && isCompanyAdministrator() ? (
                <Menu.Item key="sub0" style={{ borderRadius: "0px", textAlign: "left" }}>
                  <Link to={`/sollarbeitszeiten/all`}>Alle Filialen</Link>
                </Menu.Item>
              ) : null}
              {syncMitarbeiter && isSalonManagerAndAbove() ? (
                <SubMenu key="sub1" title="Verbund" style={{ borderRadius: "0px", textAlign: "left" }}>
                  {verbuende && filialen
                    ? verbuende.map((verbund) => (
                        <SubMenu key={verbund.verbundToken} title={verbund.alias} className={style.MenuEintrag}>
                          <Menu.Item key={verbund.verbundToken} style={{ borderRadius: "0px", textAlign: "left" }}>
                            <Link to={`/sollarbeitszeiten/verbund/${verbund.verbundToken}`}>
                              {verbund.alias} GESAMT
                            </Link>
                          </Menu.Item>
                          {verbund.filialen.map((filialKey) => {
                            const filiale = filialen.find((f) => f.filialToken === filialKey);
                            return filiale ? renderFilialeSollArbeitszeiten(filiale) : null;
                          })}
                        </SubMenu>
                      ))
                    : null}
                </SubMenu>
              ) : null}

              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialen ? filialen.map(renderFilialeSollArbeitszeiten) : null}
              </SubMenu>
            </>
          ) : null}

          {isPlaner ? (
            <>
              <SubMenu key="sub2" title="Filialen" style={{ borderRadius: "0px", textAlign: "left" }}>
                {filialenPlaner ? filialenPlaner.map(renderFilialePlaner) : null}
              </SubMenu>
            </>
          ) : null}

          {pathname === "/filial_manager" && (
            <Menu.Item key="1" icon={<EuroCircleOutlined />}>
              <Link to="/filial_manager">Dummy</Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>
    </>
  );
};

export default NavLeft;
