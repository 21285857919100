import React, { useMemo, useRef, useState } from "react";

import { Button, Input, Layout, Space, Table, Typography } from "antd";
import style from "../Datev/Datev.module.css";
import moment from "moment";
import { APIFixed, APIHorAlignment, APITabHeader, APITabSort, APIVarType } from "../../../types/apiTypes";
import { numberFormatRevers } from "../../../utils/functions";
import { SearchOutlined } from "@ant-design/icons";
import { useStammdaten } from "../../../hooks/useStammdaten";

const { Content } = Layout;
const { Title } = Typography;

const UserManager = () => {
  // eslint-disable-next-line
  const [searchText, setSearchText] = useState("");
  // eslint-disable-next-line
  const [searchedColumn, setSearchedColumn] = useState("");

  const { stammdaten } = useStammdaten();
  /**
   * DUMMYDATEN ausblenden wenn API da ist
   */
  const dummyHeader: APITabHeader = {
    ausrichtung: APIHorAlignment.APIHORALIGNMENT_CENTER,
    breiteProzent: 5,
    sortierbar: true,
    stdSortierung: APITabSort.APITABSORT_DESC,
    suchbar: false,
    text: "Dummy",
    typ: APIVarType.APIVARTYPE_STRING,
    sichtbar: true,
    statisch: APIFixed.APIFIXED_NONE,
  };
  const dummyData = {
    tabHeader: [dummyHeader, dummyHeader, dummyHeader],
    tabZeilen: [{ spalten: ["1", "1", "1"] }, { spalten: ["3", "3", "1"] }, { spalten: ["9", "9", "1"] }],
  };

  const userManagerRaw = dummyData;

  let searchInput = useRef(null);

  const getColumnSearchProps = (dataIndex: string) => ({
    // @ts-ignore
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Bitte Suchtext eingeben`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Suche
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 100 }}>
            Suche löschen
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          ></Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: JSX.Element) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value: string, record: { [x: string]: { toString: () => string } }) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      // @ts-ignore
      setTimeout(() => searchInput.current?.select(), 100);
    },
    /*    render: (text: string) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),*/
  });

  const handleSearch = (selectedKeys: string, confirm: () => void, dataIndex: string) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  // Tabellendaten generieren
  const tableData = useMemo(() => {
    const tableRAW = userManagerRaw.tabZeilen;
    let arrZeilen: {}[] = [];
    if (tableRAW !== undefined) {
      for (const apiUserTabZeile of tableRAW) {
        let zeileTemp: {} = {};
        for (let i = 0; i < apiUserTabZeile.spalten.length; i++) {
          let zeile: string = apiUserTabZeile.spalten[i];
          let identifier = "spalte" + i;
          // @ts-ignore
          zeileTemp[identifier] = zeile;
        }
        arrZeilen.push(zeileTemp);
      }
    }
    return arrZeilen;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, userManagerRaw]);

  const tableDataHeader = useMemo(() => {
    const headerRAW = userManagerRaw.tabHeader;
    let arrHeader: object[] = [];
    if (headerRAW) {
      for (let i = 0; i < headerRAW.length; i++) {
        let spalte: APITabHeader = headerRAW[i];

        let actSpaltenObject = {
          align: "left",
          dataIndex: "",
          title: "",
          width: "15",
          defaultSortOrder: "",
        };

        if (spalte.suchbar) {
          actSpaltenObject = {
            align: "left",
            dataIndex: "",
            title: "",
            width: "15",
            defaultSortOrder: "",
            ...getColumnSearchProps("spalte" + i),
          };
        }

        actSpaltenObject.title = spalte.text;
        actSpaltenObject.dataIndex = "spalte" + i;
        actSpaltenObject.width = spalte.breiteProzent + "%";

        if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_NUMBER) {
          // @ts-ignore weil optionaler Parameter
          actSpaltenObject["sorter"] = (a: { [x: string]: string }, b: { [x: string]: string }) =>
            numberFormatRevers(a["spalte" + i]) - numberFormatRevers(b["spalte" + i]);
        } else if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_DATE) {
          // @ts-ignore weil optionaler Parameter
          actSpaltenObject["sorter"] = (
            a: { [x: string]: moment.MomentInput },
            b: { [x: string]: moment.MomentInput }
          ) => moment(a["spalte" + i], "DD.MM.YYYY").unix() - moment(b["spalte" + i], "DD.MM.YYYY").unix();
        } else if (spalte.sortierbar && spalte.typ === APIVarType.APIVARTYPE_STRING) {
          // @ts-ignore weil optionaler Parameter
          actSpaltenObject["sorter"] = (a: { [x: string]: string }, b: { [x: string]: any }) =>
            a["spalte" + i].localeCompare(b["spalte" + i]);
        }
        if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_RIGHT) {
          actSpaltenObject.align = "right";
        } else if (spalte.ausrichtung === APIHorAlignment.APIHORALIGNMENT_CENTER) {
          actSpaltenObject.align = "center";
        }
        if (spalte.stdSortierung === APITabSort.APITABSORT_DESC) {
          actSpaltenObject.defaultSortOrder = "descend";
        } else if (spalte.stdSortierung === APITabSort.APITABSORT_ASC) {
          actSpaltenObject.defaultSortOrder = "ascend";
        }

        arrHeader.push(actSpaltenObject);
      }
    }
    return arrHeader;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stammdaten, userManagerRaw]);

  return (
    <Content className="global-layout-background global-content">
      <div id="headline">
        <Title level={2}>Mitarbeiterverwaltung</Title>
      </div>
      <Table
        className={style.datevTable}
        size="small"
        columns={tableDataHeader}
        pagination={{
          position: ["bottomCenter"],
          defaultPageSize: 20,
        }}
        dataSource={tableData}
        scroll={{ y: "calc(100vh - 430px)" }}
      />
    </Content>
  );
};

export default UserManager;
